@import "../../../utils";

.reg-code-list-item {
  width: 100%;
  display: flex;
  @include boxShadow(0, 1px, 0, 0);
  align-items: center;
  justify-content: space-between;

  .reg-code-wrapper {
    @include spacing(padding, xs);
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .reg-code {
      width: 20%;
      @include spacing(padding-left, xxs);
    }
  }

  .menu-button {
    border: none;
    box-shadow: none;
    :hover {
      border: none;
      box-shadow: none;
    }
  }

  .icon-button {
    border: none;
    box-shadow: none;
    :hover {
      border: none;
      box-shadow: none;

      circle {
        @include var(fill, primary-color);
      }
    }

  }
}
