@import "../../../utils";

.object-document-edit-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;

  @include breakpoint(s) {
    @include spacing(width, 500);
  }
  @include breakpoint(m) {
    @include spacing(width, 640);
  }

  .text-input-field, .select-field {
    width: 100%;
  }

  &-permissions {
    display: flex;
    flex-direction: column;

    @include breakpoint(s) {
      flex-direction: row;
    }
    .checkbox-field {
      @include spacing(margin-right,s);
      @include spacing(margin-top, 4);
    }

    &-label {
        @include spacing(margin-top, s);
    }
  }
}
