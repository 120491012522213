@import "../../utils";

.icon-button {
  box-sizing: border-box;
  font-weight: bold;
  @include spacing(border-radius, 4);
  @include spacing(height, 38);
  display: flex;
  justify-content: center;
  align-items: center;

  white-space: nowrap;
  @include var(background-color, button-background-white);
  @include var(border-color, button-border-color-low);
  border-width: 1px;
  border-style: solid;

  .icon-button-icon-path {
    @include var(fill, button-background-grey);
    opacity: 0.5;
  }

  &:not([disabled]) {
    @include boxShadow(0, 1px, 4px, 0, button-shadow);
    cursor: pointer;
    .icon-button-icon-path {
      @include var(fill, button-background-grey);
      opacity: 1;
    }
  }

  &:hover:not([disabled]) {
    @include boxShadow(0, 2px, 2px, 0, button-shadow);
  }
  &:active:not([disabled]) {
    @include boxShadow(inset, 2px, 2px, 0, button-shadow);
  }


  &-green:not([disabled]) {
    border-width: 0;
    @include var(background-color, button-background-green);
    @include var(color, button-color-green);

    .icon-button-icon-path {
      @include var(fill, button-color-green);
      opacity: 0.8;
    }

  }

  &-lightblue:not([disabled]) {
    border-width: 0;
    @include var(border-color, button-background-lightblue);
    @include var(background-color, button-background-lightblue);
    @include var(color, button-color-lightblue);

    .icon-button-icon-path {
      @include var(fill, button-color-lightblue);
      opacity: 0.8;
    }
  }

  &-red:not([disabled]) {
    @include var(border-color, button-background-red);
    @include var(background-color, button-background-red);
    @include var(color, button-color-red);

    .icon-button-icon-path {
      @include var(fill, button-color-red);
      opacity: 0.8;
    }

  }

  &-grey:not([disabled]) {
    @include var(border-color, button-background-grey);
    @include var(background-color, button-background-grey);
    @include var(color, button-color-grey);

    .icon-button-icon-path {
      @include var(fill, button-color-grey);
      opacity: 0.8;
    }

  }

}
