@import "../../utils";

.checkbox-wrapper {
  display: flex;
  align-items: flex-start;
  max-width: 400px;
  @include spacing(margin-top, s);

  label input[type="checkbox"] {
    display: none;
  }

  label input[type="checkbox"] ~ span {
    position: relative;
    display: inline-block;
    @include spacing(padding, 3, xs, 3, m);
  }

  label input[type="checkbox"] ~ span:before {
    content: "";
    position: absolute;
    display: block;
    width: 23px;
    height: 23px;
    background: #fff;
    margin-right: 5px;
    border-style: solid;
    border-width: 2px;
    border-radius: 2px;
    left: 0;
    top: 2px;
    box-sizing: border-box;
    text-align: center;
    @include var(background-color, secondary-background-color);
    @include var(border-color, diver-color);
  }

  label input[type="checkbox"]:checked ~ span:before {
    @include var(background-color, primary-color);
    @include var(color, secondary-background-color);
    content: "✓";

  }

}
