@import "../../utils";

.profile-picture {
  cursor: pointer;
  position: relative;

  &-rounded {
    border-radius: 50%;
    @include spacing(margin, 0, 0, xs, 0);

    .profile-picture-image {
      border-radius: 50%;
      display: block;
    }
  }
}
.pictures-list {
  @include spacing(margin-right, xs);
}