@import "../../utils";

.object-list-card {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;
  @include var(color, secondary-color);
  @include elevation;
  @include var(background-color, secondary-background-color);
  @include spacing(border-radius, 4);
  @include spacing(margin, xxs);
  max-width: calc(100vw - 32px);
  min-width: calc(100vw - 32px);

  @include breakpoint(s) {
    max-width: calc(50vw - 24px);
    min-width: calc(50vw - 24px);
  }
  @include breakpoint(m) {
    max-width: calc(50% - 16px);
    min-width: calc(50% - 16px);
  }
  @include breakpoint(l) {
    max-width: calc(33% - 16px);
    min-width: calc(33% - 16px);
  }
  @include breakpoint(xl) {
    max-width: calc(25% - 16px);
    min-width: calc(25% - 16px);
  }

  .object-list-card-info-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .object-list-card-info {
      display: flex;
      flex-direction: column;
      @include spacing(margin, xs);
    }

    .object-type {
      max-height: 20px;
      border-radius: 10px;
      @include spacing(padding, 0, xxs);
      @include spacing(margin, xs);
      @include var(background-color, body-background-color);
      @include var(color,  hint-color);
    }
  }

  .object-list-card-city {
    @include var(color, hint-color);
    @include spacing(margin-top, xxs);
  }

  .facility-object-picture {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: fill;

    &-icon-path {
      @include var(fill, body-background-color);
    }
  }
  .facility-object-picture-image {
    width: 100%;
    aspect-ratio: 1.7;
    border-bottom-style: solid;
    border-radius: 4px;
    @include var(border-bottom-color, primary-color);

  }
}
