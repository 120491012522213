@import "../../../utils";

.fluks-container {
  flex: 1;
  width: 100vw;
  @include spacing(margin-top, 104);
  @include spacing(padding, 0, xxs, m);

  @include breakpoint(m) {
    flex: 1;
    width: 100%;
    @include spacing(padding, m, xl);
    margin-top: 0;
  }

  &-info-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 0.25rem;
    overflow: auto;
    justify-content: center;
    @include spacing(padding, s, 0, s, 0);
    @include var(background, secondary-background-color);

    @include breakpoint(m) {
      @include spacing(padding, s, xs, s, xs);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .key-info-wrapper {
      max-width: 100%;
      @include spacing(margin, 0, s, 0, s);
      @include breakpoint(m) {
        max-width: 50%;
        @include spacing(margin, 0, s, 0, xxs);
      }

      h3 {
        @include spacing(margin, s , 0);
      }

      .api-key-existing-into-text {
        @include spacing(margin-top, m);
        @include var(color, message-state-in-progress-color);
      }

      .api-key-no-generated-into-text {
        @include spacing(margin-top, m);
        @include var(color, form-field-error-color);
      }
    }
    .key-info {
      @include var(color, hint-color);
    }

    button {
      @include spacing(margin-top, l);
    }

    .img-wrapper {
      @include spacing(margin-right, xxl);
      max-width: 100%;
      @include breakpoint(m) {
        max-width: 50%;

      }
    }
  }

}