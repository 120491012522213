@import "../../utils";

.table-sort-header {
  display: flex;
  align-items: center;
  @include spacing(padding, xxs);
  @include breakpoint(m) {
    min-width: inherit;
  }

  &-column {
    font-weight: 600;
    cursor: pointer;
    position: relative;
  }

  &-column-sortable {
    @include spacing(padding-left, m);
  };

  &-column-sortable:before {
    content: ' ';
    position: absolute;
    width:  0;
    top: 12px;
    left: 8px;
    @include spacing(right, xxs);
    border: 8px solid transparent;
    @include var(border-top-color, disable-color);
    font-size: 2px;
  }

  &-column-sortable:after, {
    content: ' ';
    position: absolute;
    left: 8px;
    top: -6px;
    width: 0;
    @include spacing(right, xxs);
    border: 8px solid transparent;
    @include var(border-bottom-color, disable-color);
    font-size: 2px;
  }
  &-column-sortable[aria-sort="ascending"]:before {
    @include var(border-top-color, button-color);
  }
  &-column-sortable[aria-sort="descending"]:after {
    @include var(border-bottom-color, button-color);
  }

  .hidden {
    visibility: hidden;
  }
}
