@import "../../../utils";

.object-documents-wrapper {

  .object-documents-container {
    @include spacing(padding, 0, xs);
    @include breakpoint(s) {
      @include spacing(padding, 0, s);
    }
    .object-documents-header {
      display: flex;
      justify-content: space-between;

    }
  }

  .object-documents-list {
    overflow: auto;
    max-height: calc(100vh - 398px);
    @include spacing(padding, xxs, 0);
    position: relative;
  }

  .table-sort-header {
    @include spacing(padding, m, s, xxs);
    &-column-originalfilename {
      flex: 1;
    }

    &-column-contentsize {
      flex-basis: 134px;
    }
    &-column-category {
      flex-basis: 124px;
    }
  }

  .table-sort-header {
    display: none;

    @include breakpoint(s) {
      display: flex;
    }
  }

  .object-documents-header {
    flex-direction: column-reverse;
    @include breakpoint(s) {
      flex-direction: row;
    }

    .button {
      @include spacing(margin, 0, 0, s);

      @include breakpoint(s) {
        @include spacing(margin, 0);
      }
    }
  }
}
