@import "../../utils";

.terms-of-services-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color:inherit;
  @include boxShadow(0, 1px, 0, 0);
  @include spacing(padding, xxs, s, xxs, 0);

  &-content {
    display: flex;
    flex-direction: column;
    @include spacing(padding, 0, xs);

    &-headline {
      font-weight: 500;
      font-size: 14px;
      margin: 0;
    }

    &-info {
      font-size: 12px;
      @include var(color, hint-color);
    }
  }

  .terms-of-services-item-element {
    display: flex;
    @include spacing(margin-right, xs);
  }

  .terms-of-services-item-buttons {
    display: flex;
    button {
      @include spacing(margin-left, xs);
    }

    > a {
      text-decoration: none;
    }
  }

  &:hover {
    @include elevation;
    .terms-of-services-item-wrapper {
      visibility: inherit;
    }
  }
}