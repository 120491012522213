@import "../../utils";

.settings-menu {
  .menu-surface {
    @include spacing(min-width, 260);
  }

  &-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include spacing(margin-bottom, m);

    .profile-info {
      @include spacing(margin-top, xxs);
      display: flex;
      flex-direction: column;
      text-align: center;

      .profile-gender-birth {
        display: flex;
        justify-content: center;
      }
      &-name {
        @include spacing(margin-bottom, xs);
      }

      div {
        @include spacing(margin-bottom, 3);
      }

      .contact-person-card-info {
        display: inline-table;
        width: 50%;
        align-items: center;
        @include var(color, hint-color);
        @include spacing(padding-left, xxs);

        a,
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          color: inherit;

        }

        .center {
          justify-content: center;
        }

        &:nth-child(even) {
          @include breakpoint(s) {
            border-left: 2px solid;
            @include var(border-color, icon-grey);
            @include spacing(margin-left, xxs);
          }
        }
      }
    }

    .about-me {
      max-width: 550px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }

    .actions-button {
      text-decoration: none;
      button {
        @include spacing(margin, 0);
        @include spacing(margin-top, l);
      }
    }

  }

}
