@import "../../../utils";

.menu-list-item {
  @include spacing(padding, xxs, xxs);
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  &-clickable {
    cursor: pointer;
    &:hover {
      @include var(color, primary-color)
    }
  }

  &-leading-icon {
    @include spacing(padding-right, xxs);
    path {
      @include var(fill, hint-color)
    }
  }

  &-active {
    @include var(background-color, navigation-background-active);
    @include boxShadow(inset, 0, 0, 0, navigation-color-active);
  }
}