@import "../../utils";

.user-settings-account-container {
  display: flex;
  flex-direction: column;
  align-items: start;

  &-button {
    @include spacing(margin, s, 0);
  }

  &-form {
    min-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: start;

    &-fields {
      width: 250px;

      @include breakpoint(m) {
        width: 350px;
      }
    }

    input {
      appearance: none;
      @include var(background-color, input-background-color);
      @include spacing(border-radius, 4);
      @include spacing(padding, xs);
      border: none;
      flex: 1;
      width:  250px;
      height: 45px;

      &::placeholder {
        opacity: 0.6;
      }

      @include breakpoint(m) {
        width: 350px;
      }
    }

    &-email {
      display: flex;
      flex-direction: column;

      span {
        @include spacing(margin-bottom, xxs);
      }
    }
  }
  .account-info-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    @include spacing(margin-bottom, s);
    @include spacing(padding-left, xs);


    @include breakpoint(s) {
      flex-direction: initial;
      width: 100%;
    }

    &:not(:last-child) {
      @include boxShadow(0, 1px, 0, 0);
    }

    .password-checkbox{
      cursor: pointer;
      display: flex;
      align-items: center;
      @include var(color, information-color);
      @include spacing(margin-top, xs);

  .checkbox {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    height: 2px;
    animation: out 0.2s cubic-bezier(1, 0, 0.58, 0.97) 1 both;
    width: 110%;
    top: 40%;
    left: -5%;
    transform: rotate(30deg);
    @include var(background-color, information-color);
  }
}

      &-view {
        @include var(fill, information-color);
      }
    }

    .email, .password, .delete {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      @include spacing(margin-right, xxs);

      @include breakpoint(s) {
        width: 33%;
      }
    }

    h3 {
      @include spacing(margin-bottom, xs);
    }

    .disable {
      @include var(color, hint-color);
    }

    > span {
      @include spacing(margin-bottom, xxs);
    }

    .notifications-info-save {
      max-width: 100px;
    }

    @include breakpoint(m) {
      @include spacing(padding-left, xxs);
    }
  }
}