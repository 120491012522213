@import "../../utils";

.user-settings-notifications-container-sections {
  .user-settings-notifications-container {
    position: relative;
    display: flex;
    align-items: start;
    flex-direction: column;
    @include spacing(padding-left, xs);
    @include spacing(margin-bottom, m);

    @include breakpoint(s) {
      @include spacing(padding-left, xxs);
      flex-direction: inherit;
    }

    .notifications-info-text {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      @include spacing(margin-right, xs);

      @include breakpoint(s) {
        max-width: 32%;
        min-width: 32%;
      }

      .switch-wrapper {
        @include spacing(margin, m, 0, xxs, 0);
      }

      h3 {
        @include spacing(margin-bottom, xxs);
      }

      .disable {
        @include var(color, hint-color);
      }

      > span {
        @include spacing(margin-bottom, xxs);
      }

      .notifications-info-save {
        max-width: 100px;
        @include spacing(margin-top, l);
      }

      &-email-settings-info {
        display: flex;
        flex-direction: column;
        @include breakpoint(l) {
          flex-direction: row;
        }
      }

      &-select {
        min-width: 200px;
        max-width: 250px;
        cursor: pointer;
        @include spacing(margin-bottom, xl);
      }
      .select-field-wrapper {
        .select-field-caret {
          @include var(fill, hint-color);
        }
      }

      &-info {
        display: inline-block;
        border-radius: 50%;
        @include spacing(width, xs);
        @include spacing(height, xs);
        @include spacing(margin, xxs, 0, xxs , xxs);
        @include spacing(line-height, xs);
        @include var(background-color, icon-blue);
        @include var(color, button-background-white);
        font-weight: 600;
        text-align: center;
        cursor: pointer;

        @include breakpoint(s) {
          position: relative;
        }
        @include breakpoint(m) {
          position: inherit;
        }
        @include breakpoint(l) {
          @include spacing(margin-top, 0);
        }

        &:hover {
          .notifications-info-text-hint {
            opacity: 1;
            display: block;
          }
        }
      }

      &-hint {
        max-width: 500px;
        display: none;
        position: absolute;
        @include spacing(padding, 3);
        @include spacing(top, 42);
        @include spacing(left, 0);
        @include spacing(right, 42);
        @include spacing(border-radius, 4);
        @include var(background-color, secondary-color);
        @include var(color, disable-color);
        transition: opacity 0.5s linear;
        user-select: none;
        z-index: 1;
        opacity: 0;
        @include breakpoint(s) {
          max-width: 500px;
          right: inherit;
          @include spacing(top, 0);
          @include spacing(left, 0);
        }
        @include breakpoint(m) {
          max-width: 500px;
          left: inherit;
          right: inherit;
          @include spacing(top, 55);
          white-space: inherit;
        }

        &-list {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          align-items: flex-start;
          text-align: start;
          margin-left: 20px;
          margin-right: 15px;
          padding: 0;

          li {
            padding-bottom: 10px
          }
        }
      }
    }

  }
}




