@import "utils";
@import "fonts";
@import "~react-loading-skeleton/dist/skeleton.css";

:root {
  // each item in color map
  @each $name, $var in $vars {
    --#{$name}: #{$var};
  }
}

body {
  @include var(background-color, body-background-color);
  font-family: Open Sans,Segoe UI,Arial,Helvetica,sans-serif;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  @include spacing(font-size, 14);

  &:has(.dialog) { overflow: hidden }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div,
header,
input,
select,
textarea {
  box-sizing: border-box;
}

h1, h2 {
  @include spacing(font-size, s);
  margin: 0;
  font-weight: 500;
  box-sizing: border-box;
}

h3 {
  @include spacing(font-size, xs);
  margin: 0;
  font-weight: normal;
}

p {
  margin: 0;
}

.green:not([disabled]) {
  @include var(color, button-background-green);
}
.red:not([disabled]) {
  @include var(color, button-background-red);
}
.grey:not([disabled]) {
  @include var(color, button-background-grey);
}

textarea {
  font-family: Open Sans,Segoe UI,Arial,Helvetica,sans-serif;
  @include spacing(font-size, 14);
  @include spacing(padding, 4);
  @include spacing(border-radius, 4);
}

textarea:active:not([readonly]),
textarea:focus:not([readonly]),
textarea:focus-visible:not([readonly]), {
  @include var(outline-color, primary-color);

}
textarea:active[readonly],
textarea:focus[readonly],
textarea:focus-visible[readonly], {
  outline: none;

}

strong {
  font-weight: 600;
}
.rmsc {
  --rmsc-h: 48px;
}