@import "../../../utils";

.etv-participants-voting-eligibility-list {
  display: flex;
  flex-direction: column;

  &-header {
    display: none;

    @include breakpoint(l) {
      display: flex;
      align-items: center;
    }

    @include spacing(padding, xs, 0);
    &-name {
      flex: 1;

    }

    &-delegate {
      flex: 1;
    }

    &-actions-wrapper {
      display: flex;
      align-items: center;
    }

    &-actions {
      min-width: 328px;
      @include spacing(padding-left, 115);
    }

    .actions-menu {
      display: none;
      @include spacing(min-width, 130);
      @include spacing(padding-left, 0);
      @include spacing(margin-right, xs);
      position: absolute;
      @include elevation;
      @include spacing(border-radius, 4);
      @include spacing(top, 130);
      @include var(background-color, secondary-background-color);

      right: 0;
      z-index: 4;

      &.open {
        display: block;
      }

      .menu-list-item {
        @include spacing(padding, xxs, xxs);
        display: flex;
        align-items: center;
        white-space: nowrap;
        cursor: pointer;
      }
    }

    &-name,
    &-actions,
    &-delegate {
      font-weight: 600;
      position: relative;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;


    @include spacing(padding, xxs);
    @include var(background-color, agenda-list-item-color);
    @include boxShadow(0, 1px, 0, 0);

    @include breakpoint(m) {
      align-items: center;
      flex-direction: row;
    }


    &-name {
      display: flex;
      align-items: center;
    }

    &-name,
    &-delegate {
      flex: 1;
      @include spacing(min-width, 200);
    }

    &-delegate-participant {
      display: flex;
      align-items: center;
      @include var(background-color, input-background-color);
      @include spacing(border-radius, 4);
      @include spacing(padding, 4);
      @include spacing(margin-right, s);
    }

    &-actions {
      width: 100%;
      display: flex;
      flex-direction: column;
      @include breakpoint(m) {
        flex-direction: row;
        width: inherit;
      }

      .button {
        @include spacing(margin, 4);
        @include spacing(min-height, xl);
        @include breakpoint(m) {
          flex: 1;
          @include spacing(min-width, 100);
        }
      }
    }
  }
}
