@import "../../../utils";

.agenda-item-proposition-voting-result {
  @include spacing(padding, xs, 0);

  &-headline {
    @include var(color, hint-color);
    @include spacing(font-size, 18);
    @include spacing(margin-bottom, s);
    @include spacing(margin-top, xl);

    @include breakpoint(s) {
      @include spacing(margin-top, xs);
    }
  }

  &-bar {
    display: flex;
    @include spacing(height, m);
    @include spacing(border-radius, 4);
    @include var(background-color, list-number-color);

    &-green,
    &-grey,
    &-red,
    &-lightgrey {
      display: block;
      @include spacing(height, m);
      @include spacing(border-radius, 4);
    }

    &-green {
      @include var(background-color, button-background-green);
    }

    &-red {
      @include var(background-color, button-background-red);
    }

    &-grey {
      @include var(background-color, button-background-grey);
    }
    &-lightgrey {
      @include var(background-color, disable-color);
    }
  }

  &-details {
    @include spacing(margin-top, m);

    &-title {
      width: 150px;
      @include var(color, hint-color);
    }
  }

  &-legend {
    display: flex;
    flex-direction: column;

    @include breakpoint(s) {
      flex-direction: row;
    }

    &-red:before, &-green:before, &-grey:before, &-lightgrey:before {
      content: "";
      display: inline-block;
      @include spacing(min-width, 14);
      @include spacing(margin-right, xxs);
      @include spacing(height, 14);
      border-radius: 50%;

    }
    &-red, &-green, &-grey, &-lightgrey {
      @include spacing(margin, xs, s, 0, 0);
      display: flex;
      align-items: center;
    }
    &-red:before {
      @include var(background-color, button-background-red);
    }
    &-green:before {
      @include var(background-color, button-background-green);
    }
    &-grey:before {
      @include var(background-color, button-background-grey);
    }
    &-lightgrey:before {
      @include var(background-color, disable-color);
    }
  }

  &-items {
    display: flex;
    flex-direction: column;
    @include breakpoint(l) {
      flex-direction: row;
    }
  }

  &-item {
    flex: 1;
  }

  &-item:first-child:not(:last-child) {
    @include spacing(padding, s, 0 , 0 ,0);
    @include breakpoint(l) {
      @include spacing(padding,0, xs, 0, 0);
    }
  }
  &-item:last-child:not(:first-child) {
    @include spacing(padding, s, 0 , 0 ,0);
    @include breakpoint(l) {
      @include spacing(padding,0, 0, 0, xs);
    }
  }

  h4 {
    @include var(color, hint-color);
    font-weight: 600;
  }
}