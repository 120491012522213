@import "../../../utils";

.textarea-field {

  &-caret {
    transform: translateX(-32px);
  }
  &-error {
    @include var(color, form-field-error-color);
    @include spacing(font-size, 14);
    @include spacing(padding, 4, 0);
  }

  &-wrapper {
    display: flex;
    align-items: center;
  }

  &-label {
    display: inline-block;
    @include spacing(padding, xxs, 0);
  }
  textarea {
    @include var(background-color, input-background-color);
    @include spacing(border-radius, 4);
    @include spacing(padding, xs);
    border: none;
    flex: 1;
    width: 100%;
  }
  textarea:focus {
    @include var(outline-color, input-focus-color);
    @include var(background-color, secondary-background-color);
  }

  &.textarea-error {
    textarea {
      @include var(outline-color, form-field-error-color);
      outline-style: solid;
      outline-width: 1px;
    }
  }

}

