@import "../../utils";

.message-create {
  height: 100%;
  z-index: 2;
  position: relative;

  &-header {
    display: flex;
    @include spacing(padding, xs, s);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    @include var(border-color, elevation-color-low-opacity);
  }

  &-content {
    height: calc(100% - 100px);
    overflow: auto;
  }

  &-form {
    @include spacing(padding, l, s);

    &-fields-visible {
      display: block;
    }

    &-fields-hidden {
      display: none;
    }

    &-actions {
      display: flex;
      justify-content: flex-end;
      @include spacing(padding, xs, 0);
    }

    &-upload {
      border: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      &-label {
        @include spacing(padding, xxs, 0);
      }
    }
  }

  &-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &-icon {
      &-path {
        @include var(fill, disable-color);
      }
    }
  }
}