@import "../../utils";

.message-details-document-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include spacing(border-radius, 4);
  @include spacing(margin, 4);
  @include spacing(padding, 4);
  @include var(background-color, input-background-color);
  position: relative;

  &.pdf-preview {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 150px;
    padding: 0;

    @include breakpoint(xl) {
      width: 420px;
    }
  }

  &-name {
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;
    width: 100%;

    span {
      padding-left: 0;
    }

    &.pdf-preview {
      span {
        @include spacing(padding-left, xxs);
      }

      .pdf-file {
        width: 20px;
        @include spacing(padding-left, 7);
      }
    }

    .truncated-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;

      @include breakpoint(s) {
        max-width: 310px;
      }
    }
  }

  &-date {
    @include spacing(font-size, 12);
    @include var(color, hint-color);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    user-select: none;
  }

  .pdf-preview-large-container {
    overflow: hidden;
    max-height: 80px;
    z-index: 0;

    @include breakpoint(xl) {
      max-height: 150px;
    }
  }

  .image-file-downloadable {
    cursor: pointer;
    width: 100%;
    &:hover {
      .download-icon {
        display: block;
      }
    }
  }

  .download-icon {
    display: none;
    position: absolute;
    @include spacing(right, 0);
    @include spacing(bottom, 20);
    border-radius: 50%;
    @include elevation;
    @include var(background-color, secondary-background-color);
  }
}
