@import "../../utils";

.etv-agenda-navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include spacing(padding, s, xs);
  @include breakpoint(m) {
    align-items: flex-end;
  }

  &-actions {
    display: flex;
  }

  &-headline {
    flex: 1;
    @include spacing(font-size, 18);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100vw - 160px);
    @include spacing(margin-right, s);
    @include breakpoint(m) {
      width: inherit;
    }
  }

  &-next,
  &-back,
  &-home {
    @include var(border-color, button-border-color);
    @include spacing(height, m);
    @include spacing(width, m);
    border-width: 1px;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-home {
    .etv-agenda-navigation-icon {
      &-path {
        @include var(fill, button-color);
      }
    }
  }

  &-disabled {
    .etv-agenda-navigation-icon {
      &-path {
        @include var(fill, disable-color)
      }
    }

  }
}