@import "../../../utils";

.messages {

  @include breakpoint(m) {
    @include spacing(padding, m, xl);
  }

  .page-header-back {
    @include breakpoint(s) {
      display: none;
    }
  }

  &-container {
    max-width: 1600px;
    margin: auto;

    @include spacing(margin-top, 116);
    @include breakpoint(m) {
      @include spacing(margin-top, 0);
    }
  }
}
