@import "../../../utils";

.etv-participants {
  position: relative;
  @include spacing(padding, m, s);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: auto;
  @include breakpoint(m) {
    width: inherit;
  }

  &-headline-wrapper {
    display: flex;
    justify-content: space-between;
    @include spacing(margin-bottom, s);

    .etv-pin-wrapper {
      display: flex;
      align-items: end;
      cursor: pointer;

      .pin-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @include spacing(margin-right, xxs);

        &-text {
          font-size: small;
          @include var(color, hint-color);
        }

        &-number {
          font-weight: bold;
        }
      }

      path {
        @include var(fill, hint-color);
      }
    }
  }

  &-headline {
    flex: 1;
    @include spacing(font-size, 18);
    @include spacing(margin-bottom, s);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100vw - 160px);
    @include spacing(margin-right, s);
    @include breakpoint(m) {
      max-width: calc(100vw - 542px);
      width: inherit;
    }
  }

  &-loading {
    @include spacing(padding, s);

    &-text {
      @include spacing(font-size, xs);
      @include spacing(padding-bottom, s);
      text-align: center;
    }

    &-spinner {
      width: 100px;
      height: 100px;
    }
  }

  .dialog-alert{
    max-width: 300px;

    @include breakpoint(s) {
      max-width: calc(90% - 100px);
    }
  }

  .dialog-title {
    display: flex;
    justify-content: center;
  }
}
