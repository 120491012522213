@import "../../../utils";

.date-input-field {

  &-label {
    display: block;
    @include spacing(padding, xxs, 0);
  }

  &-error {
    @include var(color, form-field-error-color);
    @include spacing(font-size, 14);
    @include spacing(padding, 4, 0);
  }

  input {
    @include var(background-color, input-background-color);
    @include spacing(border-radius, 4);
    @include spacing(padding, xs);
    border: none;
    flex: 1;

    &::-webkit-calendar-picker-indicator {
      @include spacing(font-size, 18);
    }
  }
  input:focus {
    @include var(outline-color, input-focus-color);
    @include var(background-color, secondary-background-color);
  }

  &.date-input-error {
    input {
      @include var(outline-color, form-field-error-color);
      outline-style: solid;
      outline-width: 1px;
    }
  }
}