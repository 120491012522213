@import "../../utils";

.etv-participants-list {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: calc(100vw - 3rem);
  @include breakpoint(m) {
    width: 100%;
  }
  @include breakpoint(l) {
    width: inherit;
  }

  .search {
    @include spacing(margin-bottom, xs);
  }

  .table-sort-header-column {
    flex-basis: 10%;
    min-width: 10%;

    &-name {
      @include spacing(padding-left, xl);
      min-width: 30%;
      flex-basis: 30%;
    }

    &-objectnumber {
      min-width: 10%;
      flex-basis: 10%;
    }

    &-facilityassignments {
      min-width: 15%;
      flex-basis: 15%;
      @include spacing(margin-left, xl);
    }

    &-delegationtype {
      flex-basis: 20%;
      min-width: 20%;
    }

    &-attendance {
      flex-basis: 20%;
      min-width: 20%;
    }

  }
  .facility-ids-not-equal.table-sort-header-column-name {
    min-width: 20%;
    flex-basis: 20%;
  }

  &-warning-info {
    text-align: start;
    font-weight: bold;
    border-radius: 5px;
    @include spacing(margin-bottom, xs);
    @include spacing(padding, xs);
    @include spacing(font-size, 14);
    @include var(background, form-field-error-background-color);
    @include var(color, form-field-error-color);
  }

  &-body {
    max-height: 60vh;
    min-height: 60vh;
    overflow: auto;
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    @include spacing(padding-top, xs);
  }
}
