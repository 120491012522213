@import "../../utils";

.bulletin-board-post-by-user {
  display: flex;


  &-date {
    @include var(color, hint-color);
    @include spacing(font-size, 12);
    @include spacing(line-height, s);
  }

  &-name {
    line-height: 1;
    @include var(color, primary-color);

    &-is-employee {
      @include var(color, employee-color);
      font-style: italic;
    }
  }

  &-content {
    @include spacing(padding-left, xxs);
  }
}
