@import "../../../utils";

.object-reports-wrapper {
   width: 100%;

  .object-reports-container {
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    @include spacing(margin-left, xs);
    h3 {
      @include spacing(margin-bottom, xxs);
    }
    .reports-descriptions-list {
      .reports-descriptions-list-item {
        list-style-type: none;
      }
    }
    .list-border {
      width: 98%;
      height: 1px;
      @include var(background-color, disable-color);
      opacity: 0.5;
      @include spacing(margin-top, s);
      @include spacing(margin-bottom, s);
    }
  }
}