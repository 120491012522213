@import "../../../utils";

.select-field {
  position: relative;

  &-caret {
    z-index: 0;
    display: block;
    position: absolute;
    @include spacing(right, xxs)
  }

  &-error {
    @include var(color, form-field-error-color);
    @include spacing(font-size, 14);
    @include spacing(padding, 4, 0);
  }
  &-wrapper {

    display: flex;
    align-items: center;
    @include var(background-color, input-background-color);
    @include spacing(border-radius, 4);


    &:has(> select:focus) {

      outline-width: 1px;
      outline-style: solid;
      @include var(outline-color, input-focus-color);
      @include var(background-color, input-active-background-color);
    }
  }

  &-label {
    display: inline-block;
    @include spacing(padding, xxs, 0);
  }
  select {
    z-index: 1;
    @include spacing(border-radius, 4);
    @include spacing(padding, xs);
    background-color: transparent;
    appearance: none;
    border: none;
    flex: 1;
    width: 100%;
  }

  select:focus {
    outline: none;
  }
  select::-ms-expand {
    display: none;
  }

  select:focus::-ms-value {
    background-color: transparent;
  }

  &.select-error {
    select {
      @include var(outline-color, form-field-error-color);
      outline-style: solid;
      outline-width: 1px;
    }
  }
}

