@import "../../../utils";

.object-picture {

  &-error {
    @include var(color, form-field-error-color);
    @include spacing(font-size, 12);
  }
  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;
   .file-button-field {
     @include spacing(margin-top, m)
   }
    &-preview {
      position: relative;
    }
    &-spinner {
      position: absolute;
      top: calc(50% - 25px);
      left: calc(50% - 25px);
    }
  }

  .dialog {
    max-width: 1066px;
  }

  .dialog-title {
    visibility: hidden;
    padding:0;
    margin: 0;
  }

}
