@import "../../utils";

.fluks-key-dialog-wrapper {
   max-width: 400px;

  .text-info {
    @include var(color,  hint-color);
  }

  label {
    display: flex;
    align-items: center;
    @include spacing(margin-left, s);
  }

  .warning-info {
    text-align: start;
    border-radius: 5px;
    @include spacing(margin-top, s);
    @include spacing(padding, xs);
    @include var(background, form-field-error-background-color);
    @include var(color, form-field-error-color);
  }
}