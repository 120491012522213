@import "../../utils";

.avatar-settings-container {
  flex: 1;
  width: 100vw;
  @include spacing(margin-top, 104);
  @include spacing(padding, 0, xxs, m);

  @include breakpoint(m) {
    flex: 1;
    width: 100%;
    @include spacing(padding, m, xl);
    margin-top: 0;
  }

  &-button {
    display: flex;
    justify-content: center;
    @include spacing(margin-top, m);
  }

  &-profile-info-wrapper {
    display: flex;
    @include var(background, secondary-background-color);
    border-radius: 0.25rem;
    @include spacing(padding, s, 0, s, 0);
    overflow: auto;
    align-items: center;
    justify-content: center;

    @include breakpoint(m) {
      @include spacing(padding, s, xs, s, xs);
      display: flex;
    }

    .avatar-settings-container-profile-info {
      max-width: 750px;

      &-text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        @include spacing(margin-bottom, s);
        @include boxShadow(0, 0.5px, 0, 0);

        span {
          @include spacing(margin-bottom, xs);
        }
      }

      .profile {
        @include boxShadow(0, 0.5px, 0, 0);

        .picture-for-user {
          display: flex;
          flex-direction: column;
          align-items: center;
          @include spacing(margin-bottom, m);

          .picture-with-pencil {
            position: relative;
          }
        }
      }

      .pictures-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: row;
        @include spacing(padding-bottom, s);
        @include boxShadow(0, 0.5px, 0, 0);

        @include breakpoint(s) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .pictures {
          display: flex;
          flex-direction: column;

          @include breakpoint(s) {
            display: flex;
            flex-direction: row;
          }
        }
      }

      .error-text {
        @include var(background, form-field-error-background-color);
        @include spacing(padding, xs);
        @include spacing(margin, s);
        border-radius: 5px;

        @include breakpoint(m) {
          @include spacing(padding, xs);
          @include spacing(margin, s, 0, 0, 0);
        }

        span {
          @include var(color, form-field-error-color);
        }
      }
    }
  }
}