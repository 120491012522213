@import "../../../utils";

.stakeholders-wrapper {
  width: 100%;
  @include boxShadow(0, 1px, 0, 0);
  align-items: center;
  justify-content: space-between;

  .stakeholders-container-title {
    font-size: 20px;
    @include spacing(margin-left, l);
  }

  .table-sort-header {
    display: flex;

    &-column {
      word-wrap: break-word;
      &-sortable {
        &[aria-label="name"] {
          &:before {
            display: none;
          }
          &:after {
            display: none;
          }
        }
      }

      &-name {
        @include spacing(padding-left, xxs);
      }
      &-name, &-e-mail {
     width: 25%;
      }

      &-account, &-reg-codes, &-status, &-code-created {
        flex: 1;
      }
    }

  }

  .reg-codes-wrapper {
    @include spacing(margin-top, l);
    @include spacing(padding-bottom, l);
    .reg-codes-list-title {
      font-size: 20px;
      @include spacing(margin-left, l);
    }
  }


  .sort-header {
    width: 100%;
    @include boxShadow(0, 1px, 0, 0);
    @include spacing(padding-bottom, xxs);
    @include spacing(padding-top, s);
  }

  .add-new-code-wrapper {
    display: flex;
    cursor: pointer;
    align-items: center;
    @include spacing(padding, xs);
    @include var(color, primary-color);

    path {
      @include var(fill, primary-color);
    }
  }

  .code-list-wrapper {
    .table-sort-header-column {
      flex-basis: 10%;
      min-width: 10%;

      &-reg-codes-without-user-sid {
        min-width: 15%;
        flex-basis: 15%;
      }
    }
  }
}
