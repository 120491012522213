@import "../../utils";

.message-list-item {
  &-content {
    @include spacing(padding, xs);
    text-decoration: none;
    display: flex;
    flex-direction: column;
    @include var("color", secondary-color);
    &:hover {
      @include elevation;
    }

    &.active {
      @include var("background-color", elevation-color-high-opacity);
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    &-title {
      flex: 1;
      word-break: break-word;
    }
  }

  &-date {
    @include var("color", hint-color);
    font-size: 12px;
  }

  &-number {
    @include var("color", message-number-color);
    @include spacing(font-size, 12);
    @include spacing(margin-left, xxs);
  }

  &:not(:last-child) {
    @include boxShadow(0, 1px, 0, 0);
  }

  &-type {
    @include spacing(border-radius, xs);
    @include spacing(padding, 2, xxs);
    @include spacing(margin-right, 2);
    @include spacing(height, 20);
    @include spacing(border-width, 2);
    @include var("border-color", secondary-background-color);
    border-style: solid;
    white-space: nowrap;
    display: block;
    float: left;
    @include var("color", hint-color);
    @include var("background-color", message-type-color);
  }
}