@import "../../../utils";

.participants-instructions-item-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  @include elevation;
  @include var(background-color, body-background-color);
  @include spacing(padding, m);
  @include spacing(margin, xxs);

  .created-date {
    display: flex;
    align-items: start;
    font-size: small;
    @include spacing(margin-bottom, xxs);
    @include var(color, hint-color);
  }

  .participants-instructions-item-name-wrapper {
    display: flex;
    align-items: flex-start;

    .participants-instructions-item-name {
      @include spacing(margin-right, xxs);
      font-size: larger;
      font-weight: bold;
    }
  }

  .instructions-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include spacing(margin-top, xxs);
    @include spacing(margin-bottom, xxs);
  }
}