@import "../../../utils";

.online-identity-list-item{
  width: 100%;
  display: flex;
  @include boxShadow(0, 1px, 0, 0);
  align-items: center;
  justify-content: space-between;

  .name-wrapper {
    @include spacing(padding, xs);
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .email {
    width: 25%;
    @include spacing(padding-left, s);
  }

  .reg-code {
    width: 12%;
    @include spacing(padding-left, xs);
  }
  .account {
    width: 12%;
    @include spacing(padding-left, xs);
  }

  .status {
    width: 12%;
    @include spacing(padding-left, xs);
  }

  .code-created {
    width: 9%;
    @include spacing(padding-left, xs);
  }

  .active {
    @include var(color, icon-green);
  }

  .inactive {
    @include var(color, hint-color);
  }

  .menu-button {
    border: none;
    box-shadow: none;
    :hover {
      border: none;
      box-shadow: none;
    }
  }

  .icon-button {
    border: none;
    box-shadow: none;
    :hover {
      border: none;
      box-shadow: none;

      circle {
        @include var(fill, primary-color);
      }
    }

  }
}
