@import "../../utils";

.search {
  @include var(border-color, input-border-color);
  @include spacing(border-radius, 4);
  border-style: solid;
  @include var(background-color, input-background-color);
  label {
    display: flex;
    @include spacing(padding, 0, 0, 0, xxs);
    @include spacing(margin-right, xxs);
    border-width: 1px;
    align-items: center;
  }
  &:has(> input:focus) {
    outline-width: 1px;
    outline-style: solid;
    @include var(outline-color, input-focus-color);
  }
  &-input {
    flex: 1;
    @include spacing(font-size, xs);
    border: none;
    background-color: transparent;
    @include spacing(padding, xxs);

    &:focus {
      outline: none;
    }
  }

  &-mobile-collapsable:not(.search-mobile-collapsed) {
    .search-input {
      @include var(background-color, input-background-color);
      @include spacing(border-radius, 4);
      @include spacing(margin-left, 4);
      @include breakpoint(s) {
        margin-left: 0;
        background-color: transparent;
      }
    }
  }
  &-mobile-collapsed {
    padding: 0;
    background-color: transparent;
    margin-right: 0;
    .search-input {
      max-width: 0;
      @include breakpoint(s) {
        max-width: initial;
      }

    }
    @include breakpoint(s) {
      @include var(border-color, input-border-color);
      @include spacing(border-radius, 4);
      border-style: solid;
      @include var(background-color, input-background-color);

    }
  }

  &-icon-path{
      @include var(fill, secondary-background-color);
    @include breakpoint(m) {
      @include var(fill, secondary-color);
    }
  }
}
