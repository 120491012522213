@import "../../../utils";

.pdf-file {
  position: relative;
  width: 100%;
  &-downloadable {
    width: 80px;
    cursor: pointer;
    &:hover {
      .pdf-file-download-icon {
        display: block;
      }
    }
  }

  object {
    background: transparent;
    user-select: none;
  }

  &-download-icon {
    display: none;
    position: absolute;
    @include spacing(right, 0);
    @include spacing(bottom, xxs);
    border-radius: 50%;
    @include elevation;
    @include var(background-color, secondary-background-color);
  }
}
