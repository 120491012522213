@import "../../utils";

.terms-of-use-content {
  width: 100vw;
  @include spacing(margin-top, 104);
  @include spacing(padding, 0, xxs, m);
  @include breakpoint(m) {
    flex: 1;
    width: 100%;
    @include spacing(padding, m, xl);
    margin-top: 0;
    min-height: 500px;
  }

  &-container {
    @include var(background-color, secondary-background-color);
    @include boxShadow( 1px, 1px, 20px, 15px);
    @include spacing(padding, xs, 0, 20);
    @include breakpoint(m) {
      @include spacing(padding, xs, xs, 20);
    }

    & > * {
      @include spacing(margin, xs, 0);
    }
  }
}