@import "../../utils";

.bulletin-board-post-list-item-edit-comment {
  display: flex;
  align-items: start;
  @include spacing(border-radius, 4);

  &-field {
    flex: 1;
    @include spacing(margin-left, s);
    position: relative;
    @include var(background, input-background-color);
    @include spacing(border-radius, 0, 4, 4, 4);
    &.hasFocus {
      @include spacing(padding, 0, xxs);
      @include var(outline-color, primary-color);
      @include var(background-color, secondary-background-color);
      outline-style: solid;
      outline-width: 2px;
      @include var(border-right-color, primary-color);
      @include var(border-top-color, primary-color);
      @include spacing(border-width, 11);
      &:before {
        @include var(border-right-color, primary-color);
        @include var(border-top-color, primary-color);
        @include spacing(border-width, 11);
        left: -22px;
        top: -2px;
      }
      .textarea-field textarea,
      .bulletin-board-post-list-item-create-comment-text-length {
        @include var(background-color, secondary-background-color)
      }
    }

    &:before {
      content: " ";
      display: block;
      position: absolute;
      @include spacing(width, 0);
      @include spacing(height, 0);
      @include var(border-right-color, input-background-color);
      @include var(border-top-color, input-background-color);
      @include spacing(border-width, 9);
      border-style: solid;
      border-left-color: transparent;
      border-bottom-color: transparent;
      top: 0px;
      left: -18px;
    }

    .textarea-field textarea {
      @include spacing(border-radius, 0, 4, 4);
      @include spacing(padding, 8, xs);
      @include spacing(margin-top, 1);
      background: transparent;
    }

    textarea:active:not([readonly]),
    textarea:focus:not([readonly]),
    textarea:focus-visible:not([readonly]), {
      outline: none;
    }
  }

  &-text-length {
    border-top-width: thin;
    border-top-style: solid;
    @include var(border-top-color, input-background-color);
    @include spacing(padding, xxs, 0);
    @include var(color, hint-color);
    @include spacing(font-size, 12);
    @include spacing(line-height, s);
    text-align: end;
    &.oversizing {
      @include var(color, form-field-error-color);
    }
  }

  .icon-button {
    @include spacing(margin-left, xxs);
  }

  .icon-button[disabled] {
    box-shadow: none;
  }
}
