@import "../../utils";

.etv-participant-representatives {
  display: flex;
  flex-direction: column;
  @include spacing(font-size, 13);
  @include var(color, hint-color);

  @include breakpoint(xl) {
    flex-direction: row;
  }

  &-item {
    cursor: pointer;
    border: none;
    @include spacing(padding-right, 0);
    @include spacing(margin-right, 0);

    @include breakpoint(xl) {
      border-right-width: 1px;
      border-right-style: solid;
      @include var(border-color, hint-color);
      @include spacing(padding-right, xxs);
      @include spacing(margin-right, xxs);
    }

    &:last-child {
      border: none;
    }

    &.readonly {
      cursor: default;
    }
  }
}