@import "../../utils";

.no-meeting-available {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
  @include breakpoint(s) {
    margin-top: 20vh;
  }
  &-title {
    text-align: center;
    @include spacing(margin, xl, 0);
  }

  &-image {
    width: 80vw;

    @include breakpoint(s) {
      width: 33vh;
    }
  }
}