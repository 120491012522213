@import "../../../utils";

.text-input-field {

  &-label {
    display: block;
    @include spacing(padding, xxs, 0);

    .info-text {
      display: flex;
      text-align: start;
      font-size: 12px;
      margin: 0;
      @include var(color, hint-color);
    }
  }

  &-error {
    @include var(color, form-field-error-color);
    @include spacing(font-size, 14);
    @include spacing(padding, 4, 0);
  }


  input {
    @include var(background-color, input-background-color);
    @include spacing(border-radius, 4);
    @include spacing(padding, xs);
    border: none;
    flex: 1;
    width: 100%;
  }
  input:focus {
    @include var(outline-color, input-focus-color);
    @include var(background-color, secondary-background-color);
  }

  &.text-input-error {
    input {
      @include var(outline-color, form-field-error-color);
      outline-style: solid;
      outline-width: 1px;
    }
  }
}