@import "../../utils";

.permissions-group-item-wrapper {
  display: flex;
  flex-direction: column;
  @include spacing(padding, xs);
  @include boxShadow(0, 1px, 0, 0);
  justify-content: space-between;
  cursor: pointer;

  &.open {
    @include spacing(padding, 0);
    cursor: auto;
  }

  .permissions-group-item-title-wrapper {
    display: flex;
    justify-content: space-between;

    &.open {
      @include spacing(padding, xs);
      cursor: pointer;
    }

    .details-title {
      @include var(color, hint-color);
    }
  }

  .caret-icon-down {
    width: 20px;
    height: 20px;
    @include var(fill, hint-color);
  }

  .caret-icon-right {
    width: 20px;
    height: 20px;
    transform: rotate(-90deg);
    @include var(fill, hint-color);
  }

  .permission {
    @include spacing(margin-top, s);
    @include spacing(padding, 0, 0, xs ,xs);
    @include boxShadow(0, 1px, 0, 0);
    cursor: auto;
  }
  .permissions-list {
    list-style: none;
    padding: 0;
    margin: 0;
    @include spacing(padding, 0, 0, s ,xs);
    @include var(background-color,  third-background-color);
    @include boxShadow(0, -1px, 0, 0);
    cursor: auto;
  }

  &:hover {
    @include elevation;
    .permissions-group-item-wrapper {
      visibility: inherit;
      @include elevation;
    }
  }
}