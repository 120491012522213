@import "../../utils";

.scroll-to-top-container {
  position: relative;
  z-index: 1000;

  .icon-button-icon {
    width: 50px;

    .icon-button-icon-path {
      @include var(fill, secondary-color);
    }
  }
  .scroll-to-top-button {
    position: absolute;
    @include spacing(right, xs);
    bottom: pxToRem(56);
    @include breakpoint(m) {
      @include spacing(right, s);
      @include spacing(bottom, s);
    }

    cursor: pointer;
    transform: rotate(90deg);
    border-style: solid;
    @include spacing(border-width, 1);
    @include spacing(padding, s, 0);
    @include spacing(border-radius, l);
    @include elevation;
    @include var(border-color, secondary-color);
    @include var(background, body-background-color);
  }
}
