@import "../../utils";

.container-contacts {
  width: 100vw;
  @include spacing(margin-top, 116);
  @include breakpoint(m) {
    @include spacing(margin-top, 104);
    flex: 1;
    width: 100%;
    @include spacing(padding, m, xl);
    margin-top: 0;
  }

  &-container {
    @include spacing(padding, 0, 0, 20);
    @include breakpoint(m) {
      @include spacing(padding, 0, xs, 20);
    }

    &-headline {
      @include spacing(margin-bottom, 20);
    }
  }
  &-contact-card-container {
    display: flex;
    flex-direction: column;
    @include breakpoint(s) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}
