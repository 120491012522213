@import "../../utils";

.tab-bar {
  @include spacing(border-radius, 4, 4 ,0, 0);
  width: 100%;
  @include var(background-color, tab-bar-background-color);

  &-scroller {
    overflow-y: hidden;

    &-area {
      display: flex;
      overflow-x: auto;
    }

    &-content {
      flex: 1 0 auto;
      position: relative;
      transform: none;
      will-change: transform;
      display: flex;
    }
  }
}