@import "../../utils";

.documents-page-container {
  flex: 1;
  width: 100vw;
  @include spacing(margin-top, 116);
  @include spacing(padding, 0, xxs, m);

  @include breakpoint(m) {
    @include spacing(margin-top, 104);
    flex: 1;
    width: 100%;
    @include spacing(padding, m, xl);
    margin-top: 0;
  }

  &-selector {
    @include var(background-color, secondary-background-color);
    @include spacing(padding, xxs, xxs, 0);
    @include breakpoint(m) {
      @include spacing(padding, xs, xs, 0);

    }
  }

  .table-sort-header {
    display: none;
    width: 100%;
    @include boxShadow(0, 1px, 0, 0);
    @include var(background-color, secondary-background-color);
    @include spacing(padding, m, 0, xs, s);

    @include breakpoint(s) {
      display: flex;
    }
    &-column {
      &-originalfilename {
        flex: 1;
      }
      &-createdateutc {
        flex-basis: 150px;
      }
      &-contentsize {
        flex-basis: 150px;
      }
      &-category{
        flex-basis: 150px;
      }
    }
  }


  .sort-header-wrapper {
    display: flex;
    flex-direction: column;

    @include breakpoint(s) {
      width: 100%;
      flex-direction: row;
    }

    .toggle-wrapper {
      @include boxShadow(0, 1px, 0, 0);
      @include var(background-color, secondary-background-color);
      .toggle-sign {
        display: inline-block;
        width: 30px;
        height: 30px;
        text-align: center;
        margin-left: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-weight: bold;
        font-size: 20px;
        cursor: pointer;
        @include spacing(margin, s, 0, xs, s);
      }

      .toggle-sign::before {
        content: '+';
        font-size: inherit;
      }

      .toggle-sign.minus::before {
        content: '-';
        font-size: inherit;
      }

      .toggle-info-text {
        @include spacing(margin-left, xs);

        @include breakpoint(s) {
         display: none;
        }
      }
    }
  }

  .documents-page-header-actions-group-button {

    background: none;
    border: none;
    box-shadow: none;
    margin: 0;
    &:hover {
      box-shadow: none;
      path {
        @include var(fill, primary-color);
      }
      svg {
        @include var(stroke, primary-color);
      }
    }

    path {
        @include var(fill, secondary-background-color);
        @include breakpoint(m) {
          @include var(fill, secondary-color);
        }
    }

    .button-icon {
      margin: 0;
      @include var(stroke, secondary-background-color);
      @include breakpoint(m) {
        @include var(stroke, secondary-color);
      }
    }
    .button-icon-trailing {
      margin: 0;
      width: 15px;
      &-path {
        @include var(fill, secondary-background-color);
        @include breakpoint(m) {
          @include var(fill, secondary-color);
        }
      }
    }
  }
}
