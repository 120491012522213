@import "../../utils";

.bulletin-board-post-list-item-report {
  &-field {
    text-align: left;
  }

  &-hint {
    @include spacing(padding, xxs);
    @include spacing(margin-top, xxs);
    @include var(color, form-field-error-color);
    @include var(border-color, form-field-error-color);
    border-style: solid;
    border-width: thin;
  }
}
