@import "../../utils";

.file-upload-preview {
  &-complete {
    max-height: 0;
    padding:0;
    transition-delay: 2s;
    transition-property: max-height;
    transition-duration: 2s;
    overflow: hidden;
  }
  &-error {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    @include spacing(padding, xs);
    background-color: rgba(255,255,255, 0.87);
  }
  &-item {
    @include spacing(padding, 4);
    position: relative;
    max-height: 127px;

    .image-file,
    .image-file img {
      max-height: 120px;
    }

    &-state {
      position: absolute;
      bottom: 8px;
      right: 8px;
      @include elevation;
      @include spacing(border-radius, xs);
      @include spacing(width, 32);
      @include spacing(height, 32);
      @include var(background-color, secondary-background-color);
      &-complete {
        &-path {
          @include var(fill, icon-green);
        }
      }
      &-error {
        &-path {
          @include var(fill, icon-red);
        }
      }
    }
  }
}
