@import "../../../utils";

.participant-attendance-state-badge {
  display: block;
  position: relative;
  @include spacing(min-width, xs);
  @include spacing(max-width, xs);
  @include spacing(height, xs);
  @include spacing(line-height, xs);
  @include spacing(font-size, xxs);
  @include var(color, secondary-background-color);
  @include var(border-color, secondary-background-color);
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  text-align: center;
  user-select: none;

  &-invitation-waiting {
    @include var(color, message-state-open-color);
    @include var(border-color, message-state-open-color);
  }

  &-invitation-accepted {
    @include var(border-color, icon-green);
    @include var(color, icon-green);
  }

  &-absent,
  &-invitation-decline {
    @include var(background-color, icon-red);
  }

  &-online {
    @include var(background-color, icon-green);
  }

  &-locally {
    @include var(background-color, icon-blue);
  }

  &-delegated-online,
  &-delegated-locally {
    @include spacing(min-width, 13);
    @include spacing(min-height, 13);
    @include spacing(height, 13);
    @include spacing(width, 13);
    @include spacing(left, 22);
    @include spacing(top, 22);
    border-style: solid;
    border-width: 2px;
    @include var(border-color, button-background-red);
  }

  &-delegated-locally {
    @include var(background-color, icon-blue);
  }

  &-delegated-online {
    @include var(background-color, icon-green);
  }

}
