@import "../../utils";

.picture-settings-wrapper {
  position: relative;

  .pencil {
    left: 90px;
    bottom: 45px;
    position: relative;
    width: 30px;
    height: 30px;
    background-color: #00b6e8;
    fill: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      @include boxShadow(0px,0px,5px,3px);
    }
  }
}
