@import "../../utils";

.invitation-dialog-delegation-wrapper {
  max-width: 500px;
  &.instructions {
    max-width: calc(100vh - 100px);
  }

  .invitation-dialog-delegation-info-wrapper {
    display: flex;
    flex-direction: column;

    @include breakpoint(s) {
      flex-direction: row;
    }

    .instructions-wrapper, .invitation-dialog-delegation-form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-basis: 50%;
      min-height: 250px;
    }

    .invitation-dialog-delegation-form {
      &.instructions {
        flex-basis: 100%;
        @include spacing(padding-left, 0);
      }
    }

    .instructions-wrapper {
      flex-basis: 50%;
      @include spacing(padding-right, 0);
      border: none;

      @include breakpoint(s) {
        @include spacing(padding-right, l);
        border-right-style: solid;
        border-right-width: 2px;
        @include var(border-color, hint-color);
      }
    }

    .invitation-dialog-delegation-form {
      @include spacing(margin-top, s);
      @include spacing(padding-left, 0);

      @include breakpoint(s) {
        @include spacing(padding-left, l);
        @include spacing(margin-top, 0);
      }
    }
  }
  .invitation-dialog-delegation-info-text {
    display: flex;
  }

  .invitation-dialog-delegation-instructions-title {
    display: flex;
    justify-content: center;
    font-size: large;
    @include spacing(margin-bottom, s);
  }

  .invitation-dialog-delegation-instructions-button {
    width: 50%;
    margin-left: 25%;
  }

  label {
    display: flex;
    text-align: left;
  }

  .invitation-dialog-delegation-description {
    max-width: 100%;
    display: flex;
    text-align: justify;
    @include spacing(margin-bottom, s);
    @include var(color, hint-color);
    justify-content: center;
  }

  .invitation-dialog-delegation-select-field-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include spacing(margin-bottom, s);

    .invitation-dialog-delegation-select-field-title {
      display: flex;
      margin-left: 30%;
      font-size: large;
      @include spacing(margin-bottom, s);
    }

  }
  .invitation-dialog-delegation-select-field {
    min-width: 400px;
  }

  .invitation-dialog-delegation-info-text {
    max-width: 400px;
  }

  .voting-eligibility-dropdown {
    width: 100%;
    &-input {
      flex: 1;
      max-width: inherit;
    }
  }

  .dialog.dialog-alert .dialog-actions {
    display: flex;
    margin-top: 20px;
  }
}