@import "../../utils";

.data-security-page-container {
  flex: 1;
  width: 100vw;
  @include spacing(margin-top, 104);
  @include spacing(padding, 0, xxs, m);

  @include breakpoint(m) {
    flex: 1;
    width: 100%;
    @include spacing(padding, m, xl);
    margin-top: 0;
  }

  &-info {
    display: flex;
    flex-direction: column;
    border-radius: 0.25rem;
    overflow: auto;
    justify-content: center;
    @include spacing(padding, s, xs, s, xs);
    @include var(background, secondary-background-color);

    @include breakpoint(m) {
      display: flex;
      justify-content: space-between;
    }

    &-title {
      @include spacing(margin-bottom, s);
      font-size: 17px;
    }

    h3 {
      font-weight: bold;
      @include spacing(margin-top, s);
    }

    &-text{
      .info-text-border-wrapper {
        border-style: solid;
        border-width: 1px;
        @include var(color, message-state-open-color);
        @include var(border-color, message-state-open-color);
        @include spacing(padding, xxs);
      }
      p {
      @include spacing(margin-top, s);
     }

      h4 {
      font-weight: bold;
      @include spacing(margin-right, xxs);
      }
      div {@include spacing(margin-top, s); }
      span {
      @include spacing(margin-left, xxs);
     }

      .table {
        display: table;
        width: 60%;
        border-style: solid;
        border-width: 1px;
        @include var(border-color, shadow-color);
      }
      .table-row {
        display: table-row;
        @include boxShadow(0, 1px, 0, 0);

        &:first-child {
          @include var(background-color, shadow-color);
        }
      }
      .table-cell {
        display: table-cell;
        @include spacing(padding, xxs, xxs);
        border-left-style: solid;
        border-left-width: 1px;
        @include var(border-left-color, shadow-color);
        a {
          padding: 0;
        }
      }
      .table-cell:nth-child(2) {
        width: 60%;
      }
     }


    a {
      text-decoration: none;
      @include var(color, information-color);
      @include spacing(padding-left, xxs);
    }
  }
}