@import "../../utils";

.meeting-list-item-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include spacing(padding, xxs);
  @include boxShadow(0, 1px, 0, 0);

  .meeting-list-item {
    width: 80%;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: flex-end;
    @include spacing(padding, xxs, xs);
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    &-content {
      @include spacing(padding, 0, xs);
      flex: 1;
    }
    &-headline {
      font-weight: 500;
      font-size: 14px;
      margin: 0;
    }

    &-info {
      font-size: 12px;
      @include var(color, hint-color);
    }

    &-actions {
      display: flex;
      flex: 0;
      align-items: center;
      justify-content: space-between;

      &-view {
        visibility: hidden;

        &-path {
          @include var(fill, primary-color);
        }
      }

      .meeting-list-item-info {
        text-align: center;
        width: 130px;
        @include var(background-color, input-background-color);
        @include spacing(border-radius, 4);
        @include spacing(padding, 4);
      }

      .actions-warning-info {
        path {
          @include var(fill, message-state-open-color);
        }
      }
    }
  }
  &:hover {
    @include elevation;
    .meeting-list-item-actions-view {
      visibility: inherit;
    }
  }

  .dialog-description {
    &:has(.etv-meeting-attend-wrapper) {
      min-height: 230px;
      padding: 0;

      @include breakpoint(s) {
        @include spacing(padding, m);
      }
    }
  }
}
