@import "../../utils";

.bulletin-board-post-list-item-menu {
  position: absolute;
  @include spacing(top, 21);
  @include spacing(right, s);
  &-action-path {
    @include var(fill, icon-grey);
  }

  &-list {
    display: none;

    &.open {
      display: block;
    }

    @include elevation;
    position: absolute;
    list-style: none;
    right: 0;
    top: 8px;
    z-index: 3;
    @include spacing(border-radius, 4);
    @include spacing(padding, 0);
    @include var(background-color, secondary-background-color);

    &-item {
      white-space: nowrap;
      @include spacing(padding, xxs);

      cursor: pointer;
      user-select: none;
      &:hover {
        @include var(background-color, elevation-color-low-opacity);
      }
      &:not(:last-child) {
        border-bottom-style: solid;
        border-bottom-width: thin;
        @include var(border-bottom-color, elevation-color-high-opacity);
      }
    }
  }
}
