@import "../../../utils";

.unsupported-file {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-message {
    @include var(color, form-field-error-color);
  }
}