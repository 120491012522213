@import "../../utils";

.contacts {
  width: 100vw;
  @include spacing(margin-top, 104);
  @include spacing(padding, 0, xxs, m);
  @include breakpoint(m) {
    flex: 1;
    width: 100%;
    @include spacing(padding, xxs, s);
    margin-top: 0;
  }
  > h2 {
    @include spacing(margin, xs, xxs);
  }

  &-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1600px;
    margin: auto;
    @include spacing(padding, 0, 0, 20);

    &-headline {
      @include spacing(margin, xxs, xxs);
    }
  }
  &-contact-card-container {
    display: flex;
    flex-direction: column;
    @include breakpoint(s) {
      flex-direction: row;
      flex-wrap: wrap;
    }


  }
}