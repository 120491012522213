@import "../../../utils";

.facility-object-settings-container {
  flex: 1;
  width: 100vw;
  @include spacing(margin-top, 104);
  @include spacing(padding, 0, xxs, m);

  @include breakpoint(m) {
    flex: 1;
    width: 100%;
    @include spacing(padding, m, xl);
    margin-top: 0;
  }

  .facility-object-settings-container-agenda {
    @include spacing(padding-top, s);
    @include var(background-color, secondary-background-color);
  }

  &-wrapper {
    flex: 1;
    @include var(background, secondary-background-color);
    border-radius: 0.25rem;
    @include spacing(padding, s, 0, s, 0);
    overflow: auto;
    height: calc(100vh - 232px);

    @include breakpoint(s) {
      height: calc(100vh - 240px);
    }
    @include breakpoint(m) {
      @include spacing(padding, s, 0, s, 0);
    }
  }
}