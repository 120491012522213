@import "../../utils";

.service-providers {
  width: 100vw;
  @include spacing(margin-top, 104);
  @include spacing(padding, 0, xxs, m);
  @include breakpoint(m) {
    flex: 1;
    width: 100%;
    margin-top: 0;
    @include spacing(padding, xxs, s)

  }

  > h2 {
    @include spacing(margin, xs, xxs);
  }

  &-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1600px;
    margin: auto;
    @include spacing(padding, 0, 0, 20);

    &-headline {
      @include spacing(margin, xxs, xxs);
      font-size: 19px;
    }
  }

  &-contact-card-container {
    display: flex;
    flex-wrap: wrap;
  }
}
