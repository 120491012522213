@import "../../../utils";

.file-input-field {

  &.file-input-error {
    .file-input-field-container {
      @include var(outline-color, form-field-error-color);
      outline-style: solid;
      outline-width: 1px;
    }
  }

  &.file-input-focused {
    .file-input-field-container {
      @include var(outline-color, input-focus-color);
      outline-style: solid;
      outline-width: 1px;
    }
  }
  &.file-input-accepted {
    .file-input-field-container {
      @include var(outline-color, icon-green);
      outline-style: solid;
      outline-width: 1px;
    }
  }
  &-label {
    display: block;
    @include spacing(padding, xxs, 0);
  }
  &-error {
    @include var(color, form-field-error-color);
  }

  &-icon {
    &-path {
      @include var(fill, input-background-color);
    }
  }

  &-text {
    text-align: center;
    @include var(color, secondary-color);
  }
  &-focused {
    .file-input-field-container {
      @include var(outline-color, input-focus-color);
      outline-style: solid;
      outline-width: 1px;
    }
  }
 &-container {
   @include var(outline-color, hint-color);
   outline-style: dashed;
   outline-width: 1px;
   @include var(color, secondary-color);
   @include spacing(border-radius, 4);
   @include spacing(padding, xxs);
   @include spacing(margin, 0, xxs);
   display: flex;
   flex-direction: column;
   align-items: center;
   cursor: pointer;

  &:hover {
    .file-input-field-icon {
      &-path {
        @include var(fill, input-focus-color);
      }
    }
  }
 }
}
