@import "../../utils";

.message-details-documents {

  &:has(.pdf-preview) {
    max-width: 180px;
    @include breakpoint(xl) {
      max-width: 450px;
    }
  }

  &-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;
  }
  &-headline {
    @include var(color, hint-color);
    @include spacing(padding, 0, 0, xs);
    display: flex;
    justify-content: space-between;
  }

  &-icon {
    path {
      @include var(fill, hint-color);
    }
  }
  &-icon-collapsed {
    transform: rotate(-90deg);
  }
}
