@import "../../utils";

.page-header {
  display: flex;
  flex-wrap: wrap;
  z-index: 3;
  position: fixed;
  top:0;
  left:0;
  right: 0;
  @include spacing(padding, 0, 4);
  width: 100vw;
  @include elevation;
  @include var(background, navigation-background-mobile);
  @include var(color, secondary-background-color);
  justify-content: space-between;

  @include breakpoint(s, portrait) {
    position: inherit;
  }
  @include breakpoint(m) {
    flex-wrap: nowrap;
    @include spacing(margin-bottom, m);
    position: sticky;
    @include var(background, body-background-color);
    color: inherit;
    width: inherit;
    @include boxShadow(0, 1px, 0, 0);
    @include spacing(padding, 0);
  }

  &-object {
    flex-basis: calc(100% + 32px) ;
    text-align: center;
    @include spacing(margin,  xxs , -16, 0, -16);

    &-title {
        @include spacing(margin, 0, xxs, 0, 0);
    }

    &-address {
      font-style: italic;
      @include spacing(font-size, 12);
      &-city {
        @include spacing(margin-left, xxs)
      }
      @include breakpoint(s) {
        @include spacing(font-size, 14);
      }
    }
    @include breakpoint(m) {
      display: none;
    }
  }
  align-items: center;

  &-object-menu {
    @include breakpoint(m) {
      display: none;
    }
  }
  &-back {
    display: none;
    @include breakpoint(m) {
      display: flex;
      align-items: center;
      cursor: pointer;
      max-width: max-content;
      @include var(color, hint-color);
      text-decoration: none;
      svg {
        transform: rotate(90deg);
        path {
          @include var(fill, hint-color);
        }
      }
    }
  }

  &-back-mobile {
    @include breakpoint(m) {
      display: none;
    }
    svg {
      transform: rotate(90deg);
      path {
        @include var(fill, secondary-background-color);
      }
    }
  }
  &-headline {
    @include spacing(padding, xxs, xs);
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    @include spacing(font-size, 18);

    @include breakpoint(m) {
      max-width: 60vw;
      @include spacing(padding, xs);
      @include spacing(font-size, s);
    }
  }

  .search {
    min-width: 30vw;
    border: none;
    .search-input {
      @include spacing(padding, xs);
    }
  }

  .search-mobile-collapsed {
    min-width: auto;
    @include breakpoint(s) {
      min-width: 30vw;
    }
    .search-input {
      @include spacing(padding, 0);
      @include breakpoint(s) {
        @include spacing(padding, xs);
      }
    }
  }
  .search-mobile-collapsable:not(.search-mobile-collapsed) {
    position: absolute;
    @include spacing(left, xxs);
    @include spacing(right, 0);
    z-index: 5;
    @include var(background-color, navigation-background-mobile);

    @include breakpoint(s) {
      position: static;
      left: inherit;
      right: inherit;
      @include var(background-color, input-background-color);
    }
    .search-input {
      &::placeholder {
        @include var(color, overlay-background-color);
        opacity: 1;
      }
    }
  }
  &-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include spacing(padding, 0, xxs);
    @include breakpoint(m) {
      flex: 1;
    }
    .button {
      @include spacing(margin-right, s);
    }

    @include breakpoint(s) {
      @include spacing(padding, 0, xs);
    }
    .notification-button {
      @include spacing(margin-right, xs);
      &-icon {
        &-path {

          @include var(fill, secondary-background-color);
          @include breakpoint(m) {
            @include var(fill, secondary-color);
          }
        }
      }
    }
  }
}
