@import "../../utils";

.dialog-description {
  &:has(.etv-meeting-attend-wrapper) {
    min-height: 230px;
    padding: 0;

    @include breakpoint(s) {
      @include spacing(padding, m);
    }
  }
}