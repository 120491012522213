@import "../../utils";

.neighbors {
  @include spacing(margin-top, 64);
  width: 100vw;
  @include spacing(padding, 0, xxs, m);
  @include breakpoint(m) {
    @include spacing(margin-top, 104);
    flex: 1;
    width: 100%;
    margin-top: 0;
    @include spacing(padding, xxs, s);
  }

  > h2 {
    @include spacing(margin, xs, xxs);
  }

  &-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1600px;
    margin: auto;
    @include spacing(padding, 0, 0, 20);
  }
}
