@import "../../../utils";

.contact-person-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  @include elevation;
  @include var(background-color, secondary-background-color);
  @include spacing(border-radius, 4);
  @include spacing(padding, xxs);
  @include spacing(margin, xxs);
  @include spacing(min-height, 248);
  min-width: calc(100% - 16px);
  max-width: calc(100% - 16px);
  @include breakpoint(s) {
    min-width: calc(50% - 16px);
    max-width: calc(50% - 16px);
  }
  @include breakpoint(l) {
    min-width: calc(33% - 16px);
    max-width: calc(33% - 16px);
  }
  @include breakpoint(xl) {
    min-width: calc(25% - 16px);
    max-width: calc(25% - 16px);
  }

  &-name {
    display: block;
    @include spacing(line-height, xs);
    width: 100%;
    text-align: center;
  }

  &-clickable {
    cursor: pointer;
  }
  &-responsibility {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include spacing(min-height, xs);

    &-dialog {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
    }

    &-name {
      @include spacing(margin, 2);

      @include spacing(font-size, 12);
      @include spacing(border-radius, 10);
      @include spacing(padding, 2, xxs);
      @include var(background-color, responsibility-name-color);
      @include var(color, button-color);
    }
  }

  &-profile-picture {
    @include spacing(margin-bottom, xs);
  }

  &-icon-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    @include spacing(font-size, xs);
    @include spacing(margin-bottom, xs);

    &-oval {
      border-radius: 100%;
      @include var(background-color, elevation-color-low-opacity);
      @include spacing(margin-bottom, xs);
      @include spacing(height, 120);
      .react-loading-skeleton {
        border-radius: 100%;

      }
    }
  }

  &-icon {
    &-path {
      @include var(fill, icon-grey);
    }
  }
  &-contacts {
    @include spacing(padding, xs, 0, xxs);
    @include spacing(height, 58);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    a {
      @include spacing(padding, 0, xxs, 0);
    }
  }
}
