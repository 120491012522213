@import "../../../utils";


.object-document-actions {
  display: none;
  @include breakpoint(l) {
    display: flex;
  }

  &.showMenu {
    position: absolute;
    display: flex;
    flex-direction: column;
    @include var(background-color, secondary-background-color);
    @include spacing(top, 36);
    z-index: 1;

    @include breakpoint(l) {
      display: flex;
      flex-direction: row;
      position: relative;
      top: 0;
    }
  }
  .icon-button {
    box-shadow: none;
    border: none;
  }
  .icon-button:not([disabled]) {
    box-shadow: none;
    border: none;

    .icon-button-icon-path {
      @include var(fill, primary-color);
    }

  }

  .icon-button-grey:not([disabled]) {
    background: none;
    .icon-button-icon-path {
      @include var(fill, button-background-grey);
    }
  }

  &-vr {
    @include spacing(margin, 0, 2);
    @include var(background-color, border-color);
    width: 1px;
  }

  &-menu {
    .object-documents-actions-menu-toggle.icon-button:not([disabled]) {
      box-shadow: none;
      border: none;
      background: none;

      .icon-button-icon-path {
        @include var(fill, button-background-grey);
      }

      @include breakpoint(l) {
        display: none;
      }
    }
  }
}
