@import "../../../utils";

.image-file {
  position: relative;
  img {
    object-fit: contain;
    width: 100%;
    display: block;
  }

  &-downloadable {
    cursor: pointer;
    &:hover {
      .image-file-download-icon {
        display: block;
      }
    }
  }
  &-download-icon {
    display: none;
    position: absolute;
    @include spacing(right, 0);
    @include spacing(bottom, xxs);
    border-radius: 50%;
    @include elevation;
    @include var(background-color, secondary-background-color);
  }
}