@import "../../../utils";

.etv-agenda-item-proposition-voting-result-for-participants {
  display: flex;
  flex-direction: column;
  @include spacing(padding, xs, 0);

  @include breakpoint(m) {
    flex-wrap: wrap;
    flex-direction: row;
  }

  .etv-participants-search {
    flex: 1;
    @include spacing(margin-bottom, xs);
  }


  .profile-picture {
    @include spacing(margin-right, xs);
  }

  &-list {
    flex: 1;
    min-width: 100%;
    overflow: auto;

    &-header {
      display: none;

      @include breakpoint(s) {
        display: flex;
      }

      @include spacing(padding, xs, 0);
      &-name,
      &-delegate {
        flex: 1;
      }

      &-name,
      &-actions {
        font-weight: 600;
        position: relative;
        @include var(color, hint-color);
      }

      &-actions {
        @include spacing(min-width, 65);
        text-align: center;
      }

    }

      &-body {
        min-height: 30vh;
        max-height: 60vh;
        overflow: auto;
      }
    .etv-participants-voting-result-list-item {
      display: flex;
      flex-direction: column;

      @include spacing(padding, xxs, 0);

      @include var(background-color, agenda-list-item-color);
      @include boxShadow(0, 1px, 0, 0);

      @include breakpoint(s) {
        align-items: center;
        flex-direction: row;
      }


      &-name {
        @include spacing(padding-left, xxs);
        @include spacing(min-width, 150);
        display: flex;
        align-items: center;
        flex: 1;
      }


      &-actions {
        @include spacing(min-width, 65);
        @include spacing(padding, 0, xs);
        text-align: right;
        @include breakpoint(s) {
          text-align: center;
          padding: 0;
        }
        font-weight: 800;
      }
    }
  }



}
