@import "../../utils";

.message-details-contact-item {
  @include spacing(padding, xs, 0);
  &-content {
    display: flex;
    flex-direction: column;

    align-items: flex-end;
    &-text {
      @include var(background-color, navigation-background-active);
      @include spacing(border-radius, xs ,0, xs, xs);
      @include spacing(padding, 0, xs, xs);
      @include spacing(margin-right, xxs);
      word-break: break-word;
      white-space: pre-wrap;
    }

    &-person {
      display: block;
      @include spacing(padding, xs, 0);
    }
    &-line {
      display: flex;
    }
  }

  .message-details-documents {
    justify-content: flex-end;
  }

  &-date {
    @include var(color, hint-color);
    @include spacing(font-size, 12);
    @include spacing(padding, 4, 56 , 4, 4);
  }

  .message-details-documents {
    @include spacing(padding, xxs);
  }
  &.odd {
    .message-details-contact-item-date {
      @include spacing(padding, 4, 4 , 4, 56);
    }
    .message-details-documents {
      justify-content: flex-start;
    }
    .message-details-contact-item-content {
      align-items: flex-start;

      &-line {
        flex-direction: row-reverse;

      }
      &-text {
        @include spacing(border-radius, 0 ,xs, xs, xs);
        @include var(background-color, body-background-color);
        @include spacing(margin-left, xxs);
      }
    }
  }
}
