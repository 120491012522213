@import "../../utils";
.dialog-background-layer {
  position: fixed;

  .dialog-responsive {
    position: absolute;
    display: flex;
    flex-direction: column;
    text-align: center;
    bottom: -100%;
    left: 0;
    width: 100%;
    margin-bottom: 0;
    transition: bottom 0.3s ease-out;
    @include spacing(border-radius, 0);
    @include boxShadow(0, 4px, 14px, 0, dialog-shadow);
    @include var(background-color, secondary-background-color);
    @include var(color, secondary-color);
    @include spacing(border-top-left-radius, xxs);
    @include spacing(border-top-right-radius, xxs);

    @include breakpoint(s) {
      max-width: 650px;
      position: relative;
      transition: none;
      margin: auto;
      @include spacing(border-radius, xs);
    }

    &.dialog-alert {
      .dialog-actions {
        justify-content: flex-end;
      }
    }
    &-title {
      @include spacing(padding, xxs);
      @include spacing(margin, 0);
      @include var(border-bottom-color, border-color);
      @include spacing(padding-top, xs);
      font-size: 18px;
      display: flex;
      justify-content: start;
      align-items: center;
      border-bottom-style: solid;
      border-bottom-width: 1px;


      @include breakpoint(s) {
      border: none;
        justify-content: center;
      }

      .close-button-wrapper{
        border: none;
        @include spacing(margin-right, -16);
      }

      .dialog-title {
        margin: 0;
        @include spacing(margin-bottom, 5);

        @include breakpoint(s) {
          padding: 0;
          @include spacing(margin-top, s);
        }

      }
    }

    &-description {
      @include spacing(padding, 0, l, m );
      display: block;
      overflow: auto;
      max-height: 100%;

      .terms-of-use-content{
        text-align: justify;
        width: 80vw;
        @include breakpoint(m) {
          max-height: 100vh;
          text-align: justify;
          &:not(.fullscreen) {
            max-width: calc(110% - 280px);
          }
        }

        & > * {
          @include spacing(margin, xs, 0);
        }
      }

      &-content {
        cursor: pointer;
        text-decoration: underline;
        @include spacing(padding, s, 0, s, 0);
      }

      &-list {
        display: flex;
        flex-direction: column;
        text-align: left;
      }
    }

    &-spinner {
      @include spacing(padding, xs);
    }
    &-actions {
      display: flex;
      flex-direction: column;
      align-items: inherit;
      border: none;
      @include spacing(padding, xs);

      @include breakpoint(s) {
        border-top-style: solid;
        border-top-width: 1px;
        flex-direction: row;
        justify-content: space-between;
        @include var(border-top-color, border-color);
      }

      &.cancel-button-hidden {
        @include breakpoint(s) {
          flex-direction: row;
          justify-content: center;
        }
      }

      .dialog-responsive-actions-cancel {
        display: none;
        @include breakpoint(s) {
          display: inherit;
        }
      }

      .pages {
        @include spacing(margin-bottom, xxs);

        @include breakpoint(s) {
          @include spacing(margin-bottom, 0);
        }
      }

      button {
        @include spacing(margin-bottom, 0);
        min-width: 75px;
      }
      .button-label{
        display: inherit;
      }
    }

    &-background-layer {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 4;
      @include var(background-color, elevation-color-high-opacity);
    }

    .button {
      margin-right: 0;
    }

    .icon-button {
      border: none;
      box-shadow: none;
      display: block;

      @include breakpoint(s) {
        display: none;
      }
    }
  }
  .dialog-responsive.show {
    bottom: 0;
  }
}

