@import "../../utils";

.profile-settings-container {
  flex: 1;
  width: 100vw;
  @include spacing(margin-top, 104);
  @include spacing(padding, 0, xxs, m);

  @include breakpoint(m) {
    flex: 1;
    width: 100%;
    @include spacing(padding, m, xl);
    margin-top: 0;
  }

  &-profile-info {
    flex: 1;
    @include var(background, secondary-background-color);
    border-radius: 0.25rem;
    @include spacing(padding, s, 0, s, 0);
    overflow: auto;

    @include breakpoint(m) {
      @include spacing(padding, s, xs, s, xs);
    }
  }
}