@import "../../../utils";

.agenda-item-proposition {
  border-top-width: 1px;
  border-top-style: solid;

  @include var(color, secondary-color);
  @include var(border-top-color, input-background-color);

  &-content {
    @include spacing(padding-top, xs);

    &-remark {
      @include spacing(padding-top, xs);
      @include var(color, hint-color);
    }
  }

  &-voting-control + .agenda-item-proposition-voting-result {
    @include spacing(margin-top, -48);

  }

  .proposition-update-form {
    @include spacing(padding-top, xs);
    @include spacing(padding-right, m);
  }

}