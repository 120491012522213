@import "../../utils";

.meeting-list {
  @include spacing(margin, xxs, xxs, l, xxs);
  @include var(background, secondary-background-color);
  @include boxShadow(0, 1px, 2px, 0);
  border-radius: 0.25rem;

  @include breakpoint(m) {
    @include spacing(margin, 0, 0, l, 0);
  }


  &-headline {
    @include var(color, hint-color);
    @include spacing(margin, 0, 0, s);
    @include spacing(padding, 0, xxs);
  }
}