@use "sass:math";

$vars: (
  body-background-color: #f2f4f7,
  secondary-background-color: #ffffff,
  third-background-color:  #fcfcfc,
  primary-color: #00e5ff,
  employee-color: #627177,
  secondary-color: rgba(0, 0, 0, 0.87),
  overlay-background-color: rgba(0, 0, 0, 0.5),
  update-layer-background-color: rgba(255, 255, 255, 0.87),
  shadow-color: #e5e7e8,
  disable-color: #bcc2c5,
  button-color: #0096bf,
  hint-color: #627177,
  list-number-color: #8e9a9e,
  elevation-color-low-opacity: rgba(33, 37, 41, 0.1),
  elevation-color-high-opacity: rgba(33, 37, 41, 0.4),
  agenda-list-item-color: rgba(188, 194, 197, 0.1),
  button-border-color: rgba(188, 194, 197, 0.1),
  button-border-color-low: rgba(188, 194, 197, 0.8),
  button-background-white: #ffffff,
  button-background-grey: #8e9a9e,
  button-background-red: #f13c3c,
  button-background-green: #3ec057,
  button-background-lightblue: #0096bf,
  button-shadow: rgba(46, 51, 65, 0.2),
  button-color-green: #ffffff,
  button-color-red: #ffffff,
  button-color-grey: #ffffff,
  button-color-lightblue: #ffffff,
  navigation-icon-color: #c7d2d7,
  navigation-color-active: #00bef2,
  navigation-background-active: rgba(0, 190, 242, 0.1),
  navigation-background-mobile: #00b6e8,
  input-background-color: rgba(188, 194, 197, 0.25),
  input-active-background-color: rgba(255, 255, 255, 0.25),
  tab-bar-background-color: rgba(188, 194, 197, 0.2),
  tab-color: #212529,
  input-border-color: #2e3341,
  input-focus-color: #bcc2c5,
  border-color: rgba(158, 171, 185, 0.2),
  diver-color: rgba(158, 171, 185, 0.5),
  dialog-shadow: rgba(0, 0, 0, 0.1),
  icon-green: #40c057,
  icon-green-disabled: rgba(67,192,87, 0.3),
  icon-red: #f03e3e,
  icon-blue: #00799a,
  icon-grey: #8e9a9e,
  information-background: rgba(0, 150, 191, 0.15),
  information-color: #00799a,
  message-state-open-background: #fff1cc,
  message-state-open-color: #cf871a,
  message-state-in-progress-background: rgba(62, 192, 87, 0.15),
  message-state-in-progress-color: #2fa545,
  message-state-closed-background: #bcc2c5,
  message-state-closed-color: #627177,
  message-type-color: rgba(33, 37, 41, 0.06),
  message-number-color: #8e9a9e,
  form-field-error-color: #f03e3e,
  form-field-error-background-color: #f03e3e4D,
  responsibility-name-color: rgba(0, 190, 242, 0.2),
  personal-background-color: rgba(240, 60, 60, 0.2),
  category-color: #00546a,
  badge-background-color-accepted: #37a14f,
  divider-color: #dde4e7,
);

$spacer: 1rem;

$spacings: (
  xxs: 0.5,
  xs: 1,
  s: 1.5,
  m: 2,
  l: 2.5,
  xl: 3,
  xxl: 3.5,
);

$media-direction: min;

$breakpoints: (
  default: "",
  xs: 480px,
  s: 667px,
  m: 1024px,
  l: 1280px,
  xl: 1680px,
);

@function pxToRem($pxValue) {
  @return calc($pxValue / 16) * 1rem;
}

@function calcSpacing($spacers) {
  $result: null;
  @each $var in $spacers {
    @if map-has-key($spacings, $var) {
      $result: $result #{map-get($spacings, $var) * $spacer};
    } @else {
      $result: $result #{pxToRem($var)};
    }
  }

  @return $result;
}

@mixin var($property, $varName) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName});
}

@mixin boxShadow($top, $left, $right, $blur, $color: null) {
  @if ($color) {
    box-shadow: $top $left $right $blur map-get($vars, $color);
    box-shadow: $top $left $right $blur var(--$color);
  } @else {
    box-shadow: $top $left $right $blur map-get($vars, shadow-color);
    box-shadow: $top $left $right $blur var(--shadow-color);
  }
}

@mixin elevation() {
  $elevationHighOpacity: map-get($vars, elevation-color-high-opacity);
  $elevationLowOpacity: map-get($vars, elevation-color-low-opacity);
  box-shadow: 0 3px 6px 0 $elevationLowOpacity, 0 0 0 1px $elevationLowOpacity,
    0 -1px 0 0 $elevationHighOpacity;
  box-shadow: 0 3px 6px 0 var(--elevation-color-low-opacity),
    0 0 0 1px var(--elevation-color-low-opacity),
    0 -1px 0 0 var(--elevation-color-high-opacity);
}

@mixin elevation-top() {
  $elevationHighOpacity: map-get($vars, elevation-color-high-opacity);
  $elevationLowOpacity: map-get($vars, elevation-color-low-opacity);
  box-shadow: 0 -3px 6px 0 $elevationLowOpacity, 0 0 0 -1px $elevationLowOpacity,
    0 1px 0 0 $elevationHighOpacity;
  box-shadow: 0 -3px 6px 0 var(--elevation-color-low-opacity),
    0 0 0 -1px var(--elevation-color-low-opacity),
    0 1px 0 0 var(--elevation-color-high-opacity);
}

@mixin spacing($property, $spacers...) {
  #{$property}: calcSpacing($spacers);
}

@mixin breakpoint($breakpoint, $direction: $media-direction) {
  // Es wird gecheckt ob der Wert in der Sass Map vorhanden ist
  @if map-has-key($breakpoints, $breakpoint) {
    // Ausgabe
    @media (#{$direction}-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

  // Wenn ihr oben einen Wert eingetragen habt wird hier gecheckt ob ihr einen Einheit angehängt habt. Falls nicht wird px genutzt.
  @else if unitless($breakpoint) {
    $breakpoint: $breakpoint + 0px;

    // Ausgabe
    @media (#{$direction}-width: $breakpoint) {
      @content;
    }
  }
}
