@import "../../../utils";

.object-document-upload-form-dialog{
  .file-input-field{
    @include spacing(margin, xxs);
  }

  .file-input-field-container {
    @include spacing(height, 360);
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint(s) {
      @include spacing(width, 500);
    }
    @include breakpoint(m) {
      @include spacing(width, 640);
    }
  }

  .file-input-field-description {
    @include spacing(margin-top, xxs);
  }

  .dialog-description {
    @include spacing(max-height, 400);
  }

  &-success {
    display: flex;
    align-items: center;

    &-icon-path {
      @include var(fill, icon-green);
    }
  }
}
