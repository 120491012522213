@import "../../../utils";

.meeting-invitation-state {
  @include spacing(padding, 0, 0, xs);

  > div {
    @include spacing(margin-bottom, 4);
    @include spacing(font-size, xs);
    display: flex;
  }

  .icon-accepted-path {
    @include var(fill, icon-green);

  }
  .icon-delegation-path {
    @include var(stroke, icon-green);

  }
  .accepted {
    @include var(color, icon-green);
  }
  .icon-declined-path {
    @include var(fill, icon-red);
  }
  .declined {
    @include var(color, icon-red);
  }

  .icon-declined {
    flex-shrink: 0;
  }

  .text-declined {
    @include spacing(margin-top, xxs);
    @include spacing(margin-left, xxs);
  }
}
