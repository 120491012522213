@import "../../utils";
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  &-container {
    position: absolute;
    @include spacing(left, xs);
    @include spacing(top, xs);
    @include spacing(right, xs);
    @include spacing(bottom, xs);
    @include spacing(border-radius, 4);
    @include var(background-color, secondary-color);
    @include var(color, secondary-background-color);
    @include elevation;
    display: flex;
    flex-direction: column;
  }

  &-action-icon-path {
      @include var(fill, secondary-background-color);
  }

  &-background-layer {
    @include var(background-color, secondary-color);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &-pdf-file {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &-header {
    display: flex;
    justify-content: flex-end;
    @include spacing(padding, xs);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    @include var(border-color, hint-color);
  }
  &-content {
    flex: 1;
    display: flex;
    @include spacing(padding, xs);
    flex-direction: column;
    @include breakpoint(s) {
      flex-direction: row;
    }
  }

  &-image {
    display: block;
    max-height: 80vh;
    max-width: 100%;
  }

  &-actions {
    @include spacing(padding, xs);
    display: flex;
    justify-content: space-evenly;
    border-top-width: 1px;
    border-top-style: solid;
    @include var(border-color, hint-color);
  }

  &-prev, &-next {
    cursor: pointer;
  }

  &-file-preview {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    @include var(background-color, dialog-shadow);
    @include breakpoint(s) {
      min-width: 70%;
    }
  }
  &-meta {
    @include spacing(padding, 0, 0, xs, xs);
    @include breakpoint(s) {
      max-width: 30%;
      min-width: 30%;
    }

    &-list {
      display: flex;
      flex-direction: column;

      dt {
        @include var(color, hint-color);
      }

      dd {
        @include spacing(margin, 0, 0, xxs, 0);
        word-break: break-all;
        padding: 0;
      }
    }
  }
}
