@import "../../utils";

.info-text-wrapper {
  width: 100%;

  .text-info {
    display: flex;
    flex-wrap: wrap;
    @include spacing(padding, xxs);
    @include spacing(margin, xs, 0);
    border-style: solid;
    border-width: 1px;
    width: 100%;

    &.success {
      @include var(color, message-state-in-progress-color);
      @include var(border-color, message-state-in-progress-color);
    }

    &.error {
      @include var(color, form-field-error-color);
      @include var(border-color, form-field-error-color);
    }

    &.info {
      @include var(color, message-state-open-color);
      @include var(border-color, message-state-open-color);
    }
  }
}
