@import "../../utils";

.bulletin-board-post-list-item {
  position: relative;
  @include spacing(padding, s, s);

  &-user {
    display: flex;
    justify-content: space-between;
  }

  &-status {
    display: flex;
    @include spacing(margin-right, s);

    &-pinned {
      &-path {
        @include var(fill, hint-color);
        opacity: 0.6;
      }
    }

    &-deactivated-comments {
      &-path {
        @include var(fill, hint-color);
        opacity: 0.6;
      }
    }

    .rectangle {
      width: 1px;
      height: 12px;
      margin: 6px 0 6px 9px;
      border-radius: 0.5px;
      opacity: 0.6;
      @include var(background-color, hint-color);
    }
  }

  &.unread {
    @include var(background-color, navigation-background-active);
  }
  &.unread:before {
    display: block;
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;

    @include spacing(width, 4);
    @include spacing(border-radius,0, 2, 2, 0);
    @include var(background-color, primary-color);
  }
  &:after {
    display: block;
    position: absolute;
    content: "";
    bottom: 0;
    left: 24px;
    right: 24px;
    height: 1px;
    @include var(background-color, diver-color);
  }

  &-container {
    display: flex;
  }

  &-content {
    @include spacing(padding, 0, xs, 0, l);
    flex: 1;
  }

  &-text {
    @include spacing(padding, xs, 0, 0);
    white-space: pre-wrap;
    word-break: break-all;
  }

  .file-upload-preview:not(:empty) {
    display: flex;
    flex-wrap: wrap;
    @include spacing(margin-top, xs);

    .file-upload-preview-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      max-width: 100px;
      @include spacing(border-radius, 4);
      @include spacing(padding, 4);
      @include spacing(margin, 4);
      @include var(background-color, input-background-color);

      .image-file {
        width: 100%;
        height: auto;
        max-height: inherit;
        img {
          object-fit: cover;
          max-height: inherit;
        }
      }
      &-name {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        user-select: none;
      }
    }
    .file-upload-preview-item-complete {
      .file-upload-preview-item-state {
        display: none;
      }
    }

    .file-upload-preview-item-error,
    .file-upload-preview-item-in-progress,
    .file-upload-preview-item-complete {
      max-height: inherit;

    }
  }
}
