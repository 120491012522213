@import "../../utils";

.drawer-header {
  @include spacing(padding, s);
  display: none;
  @include breakpoint(m) {
    display: block;
  }

  &-image {
    width: 100%;
    height: 75px;
    object-fit: contain;
  }

  a {
    text-decoration: none;
  }

  .facility-object-picture-image {
    @include spacing(border-radius, 6);
    width: 100%;
  }

  &-picture {
    display: block;
    position: relative;
    @include spacing(margin-top, s);
  }
  &-address {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    font-style: normal;
    align-items: center;
    @include spacing(margin-top, -64);
    @include spacing(padding, xs);
    @include spacing(border-radius, 0, 0, 6, 6);
    background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(0,0,0,1) 100%);
    &-content {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    &-street {
      @include spacing(font-size, xs);
      @include var(color, secondary-background-color);
    }
    &-city {
      @include spacing(line-height, xs);
      @include spacing(font-size, 13);

      @include var(color, secondary-background-color);
    }
  }
}
