@import "../../utils";

.profile-picture {
  position: relative;
  &-rounded {
    border-radius: 50%;
    .profile-picture-image {
      border-radius: 50%;
      display: block;
    }
  }

  .user-online-state-badge {
    position: absolute;
    @include spacing(bottom, -8);
    @include spacing(right, -8);
  }

  &:hover {
    .user-online-state-badge-hint {
      opacity: 1;
    }
  }
}

.profile-picture-bunch {
  display: flex;
  justify-content: flex-end;

  > .profile-picture, .menu-button {
    border: solid 2px;
    @include var(border-color, secondary-background-color);

    &:hover {
      transform: translateY(-4px);
      border: solid 2px;
      @include var(border-color, secondary-background-color);
    }
  }
  > .profile-picture:not(:first-child) {
    margin-left: -4px;
  }

  &-count-difference {
    cursor: pointer;
    display: block;
    color: white;
    text-align: center;
    vertical-align: middle;
    @include spacing(font-size, xs);
    @include var(background-color, disable-color);
    @include spacing(min-width, 32);
    @include spacing(height, 32);
    @include spacing(line-height, 32);
    border-radius: 50%;
  }
}
