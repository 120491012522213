@import "../../../utils";

.agenda-item-proposition-simple-voting-information {

  .simple-voting-container {
    display: flex;
    align-items: center;
    @include spacing(margin-top, m);

    &-info-text {
      @include spacing(margin-top, s);
    }
  }
}