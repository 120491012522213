@import "../../../utils";
.etv-participants-voting-eligibility-name {
  display: flex;

  &-container {
    display: flex;
    flex-direction: column;
    @include breakpoint(s) {
      flex-direction: row;
    }
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    @include breakpoint(s) {
      flex-direction: row;
    }
  }
  @include spacing(margin, 0, 4 ,0 ,0);
  position: relative;

  &-info-wrapper {
    display: flex;
  }

  &-info {
    display: inline-block;
    border-radius: 50%;
    @include spacing(width, xs);
    @include spacing(height, xs);
    @include spacing(margin, 0, 0, 0 ,4);
    @include spacing(line-height, xs);
    @include var(background-color, icon-blue);
    @include var(color, button-background-white);
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    @include breakpoint(s) {
      position: relative;
    }
    @include breakpoint(m) {
      position: inherit;
    }
    &:hover {
      .etv-participants-voting-eligibility-name-hint {
        opacity: 1;
        display: block;
      }
    }
  }

  &-hint {
    display: none;
    position: absolute;
    @include spacing(padding, 3);
    @include spacing(top, 42);
    @include spacing(left, 0);
    @include spacing(right, 42);
    @include spacing(border-radius, 4);
    @include var(background-color, secondary-color);
    @include var(color, disable-color);
    transition: opacity 0.5s linear;
    user-select: none;
    z-index: 1;
    opacity: 0;
    @include breakpoint(s) {
      right: inherit;
      @include spacing(top, 24);
      @include spacing(left, 0);
      white-space: nowrap;
    }
    @include breakpoint(m) {
      left: inherit;
      right: inherit;
      @include spacing(top, 42);
      white-space: inherit;
    }
  }
}