@import "../../utils";

.message-state {
    @include spacing(border-radius, xs);
    @include spacing(padding, 2, xxs);
    @include spacing(height, 20);
    @include spacing(border-width, 2);
    @include var("border-color", secondary-background-color);
    border-style: solid;
    white-space: nowrap;
    display: none;
    float: left;

    &-open {
      @include var("color", message-state-open-color);
      @include var("background-color", message-state-open-background);
    }
    &-in-progress {
      @include var("color", message-state-in-progress-color);
      @include var("background-color", message-state-in-progress-background);
    }

    &-closed {
      @include var("color", message-state-closed-color);
      @include var("background-color", message-state-closed-background);
    }
}
