@import "../../../utils";

.object-service-provider-wrapper {

  .object-service-provider-back {
  display: none;
  @include spacing(margin-left, l);
  @include spacing(margin-bottom, s);
  @include breakpoint(m) {
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: max-content;
    @include var(color, hint-color);
    text-decoration: none;
    svg {
      transform: rotate(90deg);
      path {
        @include var(fill, hint-color);
      }
    }
  }
}
  .object-service-provider-container {
    display: flex;
    align-items: start;
    flex-direction: column;

    @include breakpoint(s) {
      width: 100%;
      flex-direction: initial;
    }

    .object-service-provider-info {
      width: 100%;
      display: flex;
      flex-direction: column;

      h3 {
        @include spacing(margin-bottom, s);
        @include spacing(margin-left, xs);
      }

      &-list-body {
        width: 100%;
        overflow: auto;
        height: calc(100vh - 184px);
        @include breakpoint(s) {
          height: calc(100vh - 160px);
          margin: inherit;
        }

      }

      .sort-header {
        display: flex;
        align-items: center;
        width: 100%;
        @include boxShadow(0, 1px, 0, 0);
        @include spacing(padding-bottom, xxs);

        .checkbox-wrapper {
          @include spacing(margin, 0 , 0, 0, xs);
        }
      }

      .object-service-provider-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include var(background, shadow-color);
        @include var(color, hint-color);
        @include spacing(padding, 3,xs);
        flex-direction: column;

        @include breakpoint(s) {
          flex-direction: row;
        }

        &-buttons {
          display: flex;
          flex-direction: column;
          @include spacing(margin-bottom, xs);

          @include breakpoint(s) {
            flex-direction: row;
            @include spacing(margin-bottom, 0);
          }

          button {
            @include spacing(margin-left, 0);
            @include spacing(margin-top, xs);

            @include breakpoint(s) {
              @include spacing(margin-left, xs);
              @include spacing(margin-top, 0);
            }
          }
        }

        svg {
          @include var(fill, hint-color);
        }
      }
    }
  }
}