@import "../../utils";

.bulletin-board-post-attachments {
  display: flex;
  flex-wrap: wrap;
  @include spacing(padding, xxs, 0);
  &-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    max-width: 100px;
    @include spacing(border-radius, 4);
    @include spacing(padding, 4);
    @include spacing(margin, 4);
    @include var(background-color, input-background-color);
    .image-file {
      width: 100%;
      height: auto;
      img {
        object-fit: cover;
      }
    }
    &-name {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      user-select: none;
    }
  }
}
