@import "../../../utils";

.invitation-link-editor-buttons-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  @include breakpoint(s) {
    flex-direction: row;
  }

  button {
    @include spacing(margin-top, s);

    &:last-child {
      @include spacing(margin-left, 0);

      @include breakpoint(s) {
        @include spacing(margin-left, xs);
      }
    }

  }
}

.info-text-wrapper {
  .text-info {
    width: 100%;
    border: none;
    border-radius: 4px;
    @include var(background, message-state-open-background);
 }
}

.info-text {
  display: flex;
  flex-direction: column;
  margin: 0;

  span {
    @include var(color, message-state-open-color);
    @include spacing(margin, xxs);
  }
}
