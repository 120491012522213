@import "utils";

.drawer-small + .homecase-content:not(.fullscreen) {
  @include breakpoint(m) {
    max-width: calc(100% - 64px);
  }
}

.homecase-content.fullscreen {
  .page-header {
    display: none;
  }

  .etv-meetings {
    margin-top: 0;
  }
}

.homecase {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  @include breakpoint(m) {
    flex-direction: row;
  }
  &-content {
    position: relative;
    flex: 1;
    box-sizing: border-box;
    width: 100vw;
    max-height: 100vh;
    overflow: auto;
    @include breakpoint(m) {
      &:not(.fullscreen) {
        max-width: calc(100% - 280px);
      }
    }

    &:has(>.documents-page-container) {
      overflow: hidden;
    }

  }

  .page-loading {
    @include elevation;
    @include var(background-color, secondary-background-color);
    @include spacing(border-radius, xxs);
    height: 25vh;
    width: 50vw;
    max-width: 500px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

