@import "../../../utils";

.terms-of-use-user-list-container {
  overflow: auto;
  @include spacing(margin-top, 104);
  @include spacing(padding, 0, xxs, m);

  &-header {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include breakpoint(m) {
      flex-direction: row;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }

    .terms-of-use-users-form-fields {
      flex-direction: column;
      display: flex;

      section {
        margin-top: 5px;
        input {
          max-width: 110px;
          @include var(background-color, input-background-color);
        }
        span {
          @include spacing(padding-left, xxs);
          @include spacing(padding-top, xxs);
        }
      }

      min-width: 180px;
      @include breakpoint(s) {
        flex-direction: row;
        display: flex;
      }
    }

    &-pagination {
      display: flex;
      align-items: baseline;
      justify-content: flex-end;

      b {
        margin-left: 10px;
      }
    }
  }

  @include breakpoint(m) {
    flex: 1;
    @include spacing(padding, m, xl);
    @include spacing(margin-top, 0);
  }

  .terms-of-use-user-list {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    &-items-wrapper {
      @include spacing(min-width, 400);
      @include spacing(margin-top, xs);
      @include var(background-color, secondary-background-color);
    }

    .search {
      @include spacing(margin-bottom, xs);
    }

    &-header {
      display: flex;
      @include spacing(padding, xs, 0, xxs, 0);
      @include spacing(min-width, 400);
      @include breakpoint(m) {
        @include spacing(min-width, 200);
      }

      &-name {
        @include spacing(padding-left, s);
        @include spacing(min-width, 110);
        flex: 1;

        @include breakpoint(m) {
          @include spacing(padding-left, m);
          @include spacing(min-width, 110);
        }
      }

      &-datum {
        @include spacing(padding-left, s);
        @include spacing(max-width, 266);
        @include spacing(min-width, 100);

        @include breakpoint(m) {
          @include spacing(padding-left, m);
        }
      }

      &-version {
        @include spacing(padding-left, s);
        @include spacing(padding-right, m);
        font-weight: 600;
        text-align: center;

        @include breakpoint(m) {
          @include spacing(padding-left, m);
          @include spacing(min-width, 100);
        }
      }

      &-name,
      &-rolle,
      &-datum,
      &-version {
        font-weight: 600;
        cursor: pointer;
        position: relative;
      }

      &-name:before,
      &-rolle:before,
      &-datum:before,
      &-version:before{
        content: ' ';
        position: absolute;
        width:  0;
        top: 12px;
        left: 8px;
        @include spacing(right, xxs);
        border: 8px solid transparent;
        @include var(border-top-color, disable-color);
        font-size: 2px;
      }

      &-name:after,
      &-rolle:after,
      &-datum:after,
      &-version:after{
        content: ' ';
        position: absolute;
        left: 8px;
        top: -6px;
        width: 0;
        @include spacing(right, xxs);
        border: 8px solid transparent;
        @include var(border-bottom-color, disable-color);
        font-size: 2px;
      }
      &-name[aria-sort="ascending"]:before,
      &-rolle[aria-sort="ascending"]:before,
      &-datum[aria-sort="ascending"]:before,
      &-version[aria-sort="ascending"]:before{
        @include var(border-top-color, button-color);
      }
      &-name[aria-sort="descending"]:after,
      &-rolle[aria-sort="descending"]:after,
      &-datum[aria-sort="descending"]:after,
      &-version[aria-sort="descending"]:after{
        @include var(border-bottom-color, button-color);
      }
    }
  }
}
