@import "../../../utils";

.etv-agenda {
  position: relative;
  flex: 1;
  @include var(background, secondary-background-color);
  border-radius: 0.25rem;
  font-size: 1rem;
  @include spacing(padding, 0, 0, s, 0);
  @include spacing(margin, 104, 0, 64);

  @include breakpoint(m) {
    @include spacing(padding, xs, xs, s, xs);
   margin: 0;
  }

  .api-error {
    @include spacing(margin-top, xxl);
  }

  .agenda-list {
    @include spacing(padding, 0, xs);
  }


}

.dialog-description-wrapper {
  display: flex;
  flex-direction: column;
}
.exit-from-meeting-icon {
  width: 150px;
  height: 150px;

  @include breakpoint(s) {
    width: 650px;
    height: 350px;
  }
}
.link-to-meeting {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include spacing(padding, xs);
  @include var(background, navigation-background-active);
  @include var(color, primary-color);

  p {
    padding: 0;
    @include spacing(margin-left, xxs);

    a {
      @include var(color, secondary-color);
    }
  }
}