@import "../../utils";

.picture-preview-wrapper {
  position: relative;
  .picture-preview {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    @include spacing(margin-bottom, xs);

    img {
      border-radius: 50%;
      object-fit: cover;
      position: relative;
    }
  }
}
