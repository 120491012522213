@import "../../../utils";

.etv-agenda-item-proposition-vote-for-participants {
  position: relative;
  display: flex;
  flex-direction: column;
  @include spacing(padding, xs, 0);

  @include breakpoint(m) {
    @include spacing(padding, xs);
    flex-wrap: wrap;
    flex-direction: row;
  }

  &.forOwner {
    &:not(:empty) {
      border-top-width: 1px;
      border-top-style: solid;

      @include var(color, secondary-color);
      @include var(border-top-color, input-background-color);
    }
    flex-wrap: nowrap;
    padding: 0;

    .etv-participants-voting-eligibility-list-item {
      flex-direction: column;
      align-items: baseline;
      box-shadow: none;
      background-color: inherit;
      @include spacing(padding, xxs, 0);

      &-name {
        display: flex;
        align-items: flex-start;
        @include spacing(padding, 4);
      }

      .voting-share-wrapper {
        display: flex;
        flex-direction: column;
        @include breakpoint(s) {
          flex-direction: row;
          @include spacing(padding-left, xxs);
        }
      }
    }
    .etv-participants-voting-eligibility-list-item-actions {
      .button {
        flex: 0;
        @include breakpoint(m) {
          @include spacing(min-width, 160);
          @include spacing(margin-right, xs);
        }
      }
    }
  }

  .search {
    flex: 1;
    @include spacing(margin-bottom, xs);
  }
  .etv-participants-voting-eligibility-list-filter {
    @include spacing(margin-bottom, xs);
  }

  .profile-picture {
    @include spacing(margin-right, xs);
  }

  .etv-participants-voting-eligibility-list {
    flex: 1;
    min-width: 100%;
    overflow: auto;
  }


}