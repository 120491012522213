@import "../../../utils";

.agenda-item-proposition-voting-control {
  &-actions {
    display: flex;

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @include spacing(margin-bottom, s);

    @include breakpoint(s) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      @include spacing(margin-bottom, xs);
    }

    button {
      @include spacing(margin-bottom, xs);

      @include breakpoint(s) {
        @include spacing(margin-bottom, 0);
      }
    }

    .button:first-child {
      align-self: flex-start;
    }
  }

  &-status-text {
    font-weight: 600;
  }
}
