@import "../../utils";

.agenda-list-skeleton {

  display: flex;
  flex-direction: column;
  counter-reset: section;


  @include breakpoint(m) {
    @include spacing(padding, 0, s);
  }
}

.agenda-list-item-skeleton {
  @include spacing(padding, xxs, s);
  @include var(background, agenda-list-item-color);
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    @include elevation;
  }

  &:before {
    @include spacing(padding, xxs, xxs, xxs, 0);
    @include var(color, list-number-color);
    counter-increment: section;
    content: counter(section) ".";
    display: block;
    font-size: 18px;
    font-weight: 600;
  }

  &:not(:last-child) {
    @include boxShadow(0, 1px, 0, 0);

    &:hover {
      @include elevation;
    }
  }

  &-headline {
    @include var(color, secondary-color);
    font-weight: 500;
    font-size: 14px;
    margin: 0;

    .react-loading-skeleton {
      width: 50%;
    }
  }

  &-info {
    @include var(color, hint-color);
    font-size: 13px;
    margin: 0;
  }

  &-content {
    flex: 1;
  }
}