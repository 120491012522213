@import "../../../utils";

.objects-container {
  width: 100vw;
  overflow: auto;
  @include spacing(margin-top, 104);
  @include spacing(padding, 0, xxs, m);

  @include breakpoint(m) {
    flex: 1;
    width: 100%;
    @include spacing(padding, m, xl);
    @include spacing(margin-top, 0);
  }
}
