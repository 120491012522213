@import "../../utils";

.not-found {
  width: 100vw;
  display: flex;
  flex-direction: column;
  @include spacing(margin-top, 104);
  @include spacing(padding, 0, xxs, m);
  @include breakpoint(m) {
    height: 100vh;
    width: 100%;
    @include spacing(padding, m, xl);
    margin-top: 0;
  }

  &-content {
    @include spacing(padding, xs);
    display: flex;
    flex-direction: column;
    @include breakpoint(m) {
      flex-basis: 70%;
      @include spacing(padding, xxs, s);
    }

    &-block {
      @include spacing(padding, xxs, 0);
    }
  }

  &-container {
    flex: 1;
    margin: auto;
    display: flex;
    max-width: 1024px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    @include var(background-color, secondary-background-color);
    @include elevation;
    @include spacing(padding, xs );
    @include spacing(border-radius, 4);
    @include breakpoint(m) {
      @include spacing(padding, l);
      flex-direction: row;
    }

    &-headline {
      @include spacing(margin-bottom, 20);
    }
  }
}
