@import "../../utils";

.dialog-description-delegate-before-leaving {
  max-width: 500px;
  min-height: 200px;

  .description-text {
    @include spacing(margin-bottom, m);
  }

  .voting-eligibility-dropdown {
    @include spacing(margin-top, m);
    min-width: 250px;
    z-index: 1000;

    @include breakpoint(s) {
      min-width: 500px;
    }
  }
}