@import "../../../utils";

.file-preview {
  &-item {
    position: relative;
    @include spacing(padding, 0, xxs, 0, 0);
    @include spacing(margin-bottom, xxs);
    display: flex;
    align-items: center;
    flex-direction: column;
    @include spacing(width, 80);
    @include spacing(height, 80);

    &-name {
      @include spacing(font-size, 12);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include spacing(width, 80);
      box-sizing: border-box;
      text-align: center;
    }

    &-remove {
      position: absolute;
      display: flex;
      border-radius: 50%;
      @include var(background-color, secondary-background-color);
      @include elevation;
      @include spacing(width, m);
      @include spacing(height, m);
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include spacing(top, 0);
      @include spacing(right, 0);
      z-index: 3;
    }
  }
}