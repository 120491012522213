@import "../../utils";

.drawer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 3;
  flex-direction: column;
  &.drawer-small {
    @include breakpoint(m) {
      @include spacing(width, 64);
      @include spacing(min-width, 64);
    }

    .navigation-list-item-title {
      @include breakpoint(m) {
        display: none;
      }
    }

    .navigation-list > .navigation-list {
      @include breakpoint(m) {
        display: none;
      }
    }
    .navigation-list-item-link {
      @include breakpoint(m) {
        @include spacing(padding, xxs, xs);
      }
    }

    .navigation-list-footer {
      @include breakpoint(m) {
        display: none;
      }
    }

    .drawer-header {
      @include breakpoint(m) {
        visibility: collapse;
        padding: 0;
      }



    }

    .icon-button {
      border-radius: 50%;

      .icon-button-icon {
        transform: rotate(-90deg);
      }
    }
  }
  @include breakpoint(m) {
    min-width: 280px;
    transform: translateX(0);
    position: relative;
    width: 280px;
  }
  @include var(background-color, secondary-background-color);

  &-action-button {
    display: none;

    &.icon-button {
      border-radius: 50%;
      @include spacing(height, 30);
      .icon-button-icon {
        transform: rotate(90deg);
      }
    }
    @include breakpoint(m) {
      position: absolute;
      display: inherit;
      @include spacing(right, -16);
      @include spacing(top, s);
    }
  }
}
