@import "../../utils";

.notification-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  &-icon {
    &-path {
      @include var(fill, secondary-color);
    }

  }

  &-count {
    border-radius: 50%;
    @include var(background-color, primary-color);
    @include var(color, secondary-background-color);
    @include spacing(width, xs);
    @include spacing(height, xs);
    @include spacing(line-height, xs);
    @include spacing(font-size, 10);
    text-align: center;
    position: absolute;
    @include spacing(top, -8);
    @include spacing(right, -8);
  }
}

.notification-button:hover {
  .notification-button-icon {
    &-path {
      @include var(fill, primary-color);
    }
  }
}

.notifications {
  &-dialog {
    max-width: 400px;
    margin: auto;
    @include boxShadow(0, 4px, 14px, 0, dialog-shadow);
    @include var(background-color, secondary-background-color);
    @include var(color, secondary-color);
    @include spacing(border-radius, xxs);
    flex-direction: column;
    text-align: center;
    align-items: center;

    &-header {
      @include spacing(padding, xs);
      @include var(border-bottom-color, border-color);
      border-bottom-style: solid;
      border-bottom-width: 1px;
      display: flex;

      &-title {
        text-align: left;
        @include var(color, secondary-color);
        @include spacing(margin-right, xxs);
        flex: 1;
      }

      &-actions {
        text-align: right;
        font-size: small;
        @include var(color, navigation-color-active);
        cursor: pointer;
      }
    }

    &-content {
      max-height: 450px;
      overflow-y: auto;
      display: block;
    }

    &-spinner {
      @include spacing(padding, 0, l, l);
    }

    &-background-layer {
      display: flex;
      position: absolute;
      @include spacing(top, 32);
      @include spacing(right, 0);
      @include spacing(padding, xxs);
      @include breakpoint(s) {
        @include spacing(right, 16);
      }
      @include breakpoint(m) {
        @include spacing(top, 72);
        @include spacing(right, 50);
      }
      z-index: 5;
    }
  }
}

.notification-item {
  @include spacing(padding, xxs, xs);
  @include var(color, secondary-color);
  display: flex;
  text-decoration: none;
  text-align: left;
  flex: 1;

  align-items: normal;
  border-left-color: transparent;
  border-left-style: solid;
  border-left-width: 2px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  @include var(border-bottom-color, agenda-list-item-color);

  &-unread {
    @include var(background-color, navigation-background-active);
    @include var(border-left-color, navigation-color-active);
  }

  &:hover {
    @include var(background-color, navigation-background-active);
    @include var(border-left-color, navigation-color-active);
  }

  &-profile-picture {
    cursor: pointer;
  }

  &-content {
    @include spacing(padding, 0, xxs);
    hyphens: auto;

    strong {
      @include spacing(margin-right, 4);
    }
    &-text {
      @include spacing(padding, xxs, 0);
      font-size: 12px;
    }

    &-date {
      @include var("color", hint-color);
      font-size: 12px;
    }
  }
}