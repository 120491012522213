@import "../../utils";

.etv-meeting-attend {
  &.meeting-info-page > .button-take-part {
      position: fixed;
      bottom: 60px;
      left: 32px;
      right: 12px;

     @include breakpoint(m) {
       position: initial;
     }

    .button-label{
      display: inherit;
    }
  }

  &.meeting-info-page {
    button[aria-label="Teilnehmen"] {
      display: flex;
      position: fixed;
      bottom: 60px;
      right: 30%;

      @include breakpoint(s) {
        position: static;
        bottom: initial;
      }
    }
  }

  &-info {
    font-size: 12px;
    @include var(color, hint-color);
    @include var(background-color, input-background-color);
    @include spacing(border-radius, 4);
    @include spacing(padding, xxs);
    text-align: center;
    display: block;
    height: 30px;
    box-sizing: border-box;

    @include breakpoint(m) {
      width: 140px;
      height: inherit;
      @include spacing(padding, 4, xxs);
    }
  }

  .button-icon {
    margin-right: 0;
    @include breakpoint(s) {
      @include spacing(margin-right, xxs);
    }
  }
  .button-icon + .button-label{
    display: none;
    @include breakpoint(s) {
      display: inherit;
    }
  }
  button[aria-label="Teilnehmen"] {
    .button-icon + .button-label {
      display: inherit;
    }
  }

  .dialog-description {
    &:has(.etv-meeting-attend-wrapper) {
      min-height: 230px;
      padding: 0;

      @include breakpoint(s) {
        @include spacing(padding, m);
      }
    }
  }
}
