@import "../../utils";

.employees-page-container {
  flex: 1;
  width: 100vw;
  @include spacing(margin-top, 104);
  @include spacing(padding, 0, xxs, m);

  @include breakpoint(m) {
    flex: 1;
    width: 100%;
    @include spacing(padding, m, xl);
    margin-top: 0;
  }

  &-info-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 0.25rem;
    overflow: auto;
    justify-content: center;
    @include var(background, secondary-background-color);
    @include elevation;

    @include breakpoint(m) {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      justify-content: space-between;
    }

    .employees-page-container-header {
      display: flex;
      justify-content: space-between;
      @include boxShadow(0, 2px, 0, 0);
      @include spacing(padding, xs, xxl);

      min-width: 790px;
      @include breakpoint(l) {
        width: 100%;
      }

      &-email,
      &-employees {
        font-weight: bold;
      }

      &-employees {
        font-weight: bold;
        min-width: 160px;
      }

      &-email {
        display: flex;
        justify-content: flex-start;
        min-width: 560px;

        @include breakpoint(l) {
          min-width: 670px;
        }
      }
    }

    .employees-page-container-info {
      display: flex;
      flex-direction: column;
      min-width: 900px;
      @include breakpoint(m) {
        width: 100%;
      }
    }
  }

}