@import "../../../utils";

.permissions-container-page {
  flex: 1;
  width: 100vw;
  @include spacing(margin-top, 104);
  @include spacing(padding, 0, xxs, m);

  @include breakpoint(m) {
    flex: 1;
    width: 100%;
    @include spacing(padding, m, xl);
    margin-top: 0;
  }

  .permissions-container-info-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 0.25rem;
    overflow: auto;
    justify-content: center;
    @include var(background, secondary-background-color);
    @include elevation;

    @include breakpoint(m) {
      display: flex;
      justify-content: space-between;
    }
  }

}