@import "../../utils";

.bulletin-board-post-create-post-select-objects {
  display: flex;
  flex-direction: column;
  position: relative;
  @include breakpoint(s) {
    min-width: 640px;
  }
  span {
    display: block;
    text-align: left;
  }
  .multi-checkbox-field {
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: row;
      @include breakpoint(s) {
        @include spacing(padding, m, xs);
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
      }
    }

    &-item {
      text-align: left;
      @include spacing(padding, xxs, 0)
    }
  }

  .multi-checkbox-field-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 40vh;
    min-height: 40vh;

    &-header {
      display: flex;
      justify-content: space-between;
      @include spacing(padding-bottom, xs);

      border-bottom-style: solid;
      border-bottom-width: thin;
      @include var(border-bottom-color, diver-color);
    }
    &-filter {
      display: flex;
      @include spacing(padding, xs, 0);

      .search {
        flex: 1;
      }
      .select-field-caret {
        z-index: 0;
      }
    }
    .multi-checkbox-field-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      input {
        @include spacing(margin-right, xxs);
      }
    }
    .multi-checkbox-field-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom-style: solid;
      border-bottom-width: thin;
      @include var(border-bottom-color, diver-color);
      cursor: pointer;

      &:hover {
        @include elevation;
      }

      .object-name {
        flex: 1;
        @include spacing(padding, 0, xxs);
      }
      .object-type {
        @include spacing(padding, 4, xxs);
        @include spacing(border-radius, 4);
        @include spacing(margin-right, 4);
        @include var(border-color, information-color);
        @include var(color, information-color);
        border-width: thin;
        border-style: solid;
        flex-basis: 30px;
        text-align: center;
      }
      .object-state {
        @include spacing(padding, 4, xxs);
        @include spacing(border-radius, 4);
        @include spacing(margin-right, 4);
        @include var(border-color, button-background-red);
        @include var(color, button-background-red);
        border-width: thin;
        border-style: solid;

        &.online {
          @include var(border-color, button-background-green);
          @include var(color, button-background-green);
        }
      }
    }
  }
}
