@import "../../utils";

.information {
  &-box {
    @include var(background-color, information-background);
    @include spacing(padding, xs);
    border-radius: 4px;
    @include var(color, information-color);
  }
    &-icon {
      display: inline-block;
      @include spacing(width, s);
      @include spacing(height, s);
      @include spacing(margin-right, xs);
      @include spacing(line-height, s);
      @include var(border-color, information-color);
      @include var(color, information-color);
      text-align: center;
      font-weight: 600;
      border-width: 2px;
      border-style: solid;
      border-radius: 50%;
    }
}