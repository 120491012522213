@import "../../../utils";

.proposition-voting-resolution-form-fields {
  display: flex;
  flex-direction: column;

  .select-field {
    cursor: pointer;
    @include spacing(margin-bottom, 0);
    @include spacing(margin-left, xs);

    &-caret {
      @include spacing(margin-left, xxl);

      @include breakpoint(s) {
        @include spacing(margin-right, s);
      }
    }

    label {
      display: flex;
      flex-direction: column;

      .select-field-label {
        @include spacing(margin-right, m);
        text-align: start;
      }
    }
  }
}
