@import "../../utils";



  .employees-add-dialog-description-field-wrapper {

    label {
      display: flex;
      flex-direction: column;
      @include spacing(margin-top,xxs);

      span {
        text-align: start;
      }
    }

    .name-info-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @include breakpoint(m) {
        flex-direction: row;
      }

      label {
        @include breakpoint(m) {
          min-width: 250px;
        }
      }
    }
  }


