@import "../../utils";

.management-item-container {
  width: 100%;
  min-width: 270px;
  @include var(background-color, input-background-color);
  display: flex;
  cursor: pointer;
  @include spacing(padding, xxs);
  @include spacing(margin, s, 0);
  white-space: pre-wrap;
  align-items: center;

  .management-item-container-logo {
    @include spacing(border-radius, 4);
    @include elevation;
    @include spacing(padding, xxs);
    @include spacing(margin-right, s);
    @include var(background-color, secondary-background-color);
  }
}