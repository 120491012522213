@import "../../utils";

.user-settings-menu {
  .menu-surface {
    @include spacing(min-width, 260);
  }

  &-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-picture-rounded {
    margin: 0;
  }
}
