@import "../../utils";

.meeting-list-skeleton {
  @include spacing(margin, xxs, xxs, l, xxs);
  @include var(background, secondary-background-color);
  @include boxShadow(0, 1px, 2px, 0);
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;

  @include breakpoint(m) {
    @include spacing(margin, 0, 0, l, 0);
  }


  &-headline {
    @include var(color, hint-color);
    @include spacing(margin, 0, 0, s);
    @include spacing(padding, 0, xxs);
    .react-loading-skeleton {
      width: 40%;
    }
  }
}

.meeting-list-item-skeleton {
  flex: 1;
  display: flex;
  align-items: center;
  @include spacing(padding, xxs, xs);
  @include boxShadow(0, 1px, 0, 0);
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  &-content {
    @include spacing(padding, 0, xs);
    flex: 1;
  }
  &-headline {
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    .react-loading-skeleton {
      width: 50%;
    }
  }

  &-info {
    font-size: 12px;
    @include var(color, hint-color);
    .react-loading-skeleton {
      width: 33%;
    }
  }

  &-actions {
    display: flex;
    flex: 0;
    &-view {
      visibility: hidden;
      &-path {
        @include var(fill, primary-color);
      }
    }
  }

  &:hover {
    @include elevation;
    .meeting-list-item-actions-view {
      visibility: inherit;
    }
  }
}