@import "../../utils";

.terms-of-use-users-form {
  display: flex;
  @include spacing(margin-bottom, s);
  align-items: flex-end;

  &-fields {
    display: flex;
    @include spacing(margin-right, xxs);

    section {
      @include spacing(margin-left, m);

      .select-field-wrapper {
        min-width: 155px;
      }

      input {
        min-width: 155px;
        display: flex;
        align-items: center;
        @include spacing(border-radius, 4);
        @include spacing(padding, xs);

        &::placeholder {
          @include var(color, secondary-color);
        }
        &:focus {
          outline-width: 1px;
          outline-style: solid;
          @include var(outline-color, input-focus-color);
          @include var(background-color, input-active-background-color);
        }
      }

      label {
        display: flex;

        span {
          @include spacing(margin-right, xxs);
        }
      }
    }

    .textarea-field {
      flex: 1;
      @include spacing(padding, 0, xxs);

      textarea {
        @include spacing(padding, xxs);
      }
    }

    .icon-button {
      height: 54px;
    }
  }

  button {
    @include spacing(margin-left, s);
  }

  &-preview {
    display: flex;
    overflow: auto;
  }
}