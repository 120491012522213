@import "../../../utils";

.agenda-item-proposition-vote-control-center {

  &-header-wrapper {
    width: 100%;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    @include breakpoint(s) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .search {
      flex: 1;
    }

  }

  .search {
    @include spacing(margin, xs, 0);
  }

  &-table {
    @include var(background-color, secondary-background-color);
    @include spacing(padding, xxs, xs);

    .table-sort-header-column-sortable:after, {
      top: -8px
    }

    &-header {
      display: none;

      @include breakpoint(l) {
        display: flex;
        align-items: center;
      }

      @include spacing(padding, xs, 0);
      &-name {
        flex: 1;
      }

      &-delegate {
        flex: 1;
      }

      &-actions-wrapper {
        display: flex;
        align-items: center;
      }

      &-actions {
        @include spacing(padding-left, 115);
        @include spacing(padding-right, s);

        &-button {
          box-shadow: none;
          border: none;
          height: auto;
          @include spacing(margin-left, 0);
          @include breakpoint(s) {
            @include spacing(margin-left, l);
          }

          &.button-active{
            @include var(color, primary-color);
          }

          svg {
            @include var(fill, primary-color);
            .button-icon-trailing {
              width: 15px;
            }
          }

        }
      }

      .actions-menu {
        display: none;
        @include spacing(min-width, 130);
        @include spacing(padding-left, 0);
        @include spacing(margin-right, xs);
        position: absolute;
        @include elevation;
        @include spacing(border-radius, 4);
        @include spacing(top, 130);
        @include var(background-color, secondary-background-color);

        right: 0;
        z-index: 4;

        &.open {
          display: block;
        }

        .menu-list-item {
          @include spacing(padding, xxs, xxs);
          display: flex;
          align-items: center;
          white-space: nowrap;
          cursor: pointer;
        }
      }

      &-name,
      &-actions,
      &-delegate {
        font-weight: 600;
        position: relative;
      }

      .table-sort-header {
        width: 100%;

        &-column-name, .sort-title:first-child {
          @include spacing(padding-left, l);
          flex: 1;
        }

        &-column-delegationtype, .sort-title:nth-child(2) {
          flex: 1;
          @include spacing(margin-right, xxl);
          @include spacing(padding-right, xxl);
        }

        &-column-vote, .sort-title:nth-child(3) {
          @include spacing(margin-right, xxl);
          @include spacing(padding-right, xxl);
          @include spacing(margin-left, xxl);
          @include spacing(padding-left, xxl);
        }
      }
    }

    &-body {
      min-height: 30vh;
      max-height: 60vh;
      overflow: auto;
      .etv-participants-voting-eligibility-name {
        @include spacing(margin-left, xs);
      }
    }

    &-row {
      display: flex;
      flex-direction: column;

      @include spacing(padding, xxs);
      @include var(background-color, agenda-list-item-color);
      @include boxShadow(0, 1px, 0, 0);

      @include breakpoint(m) {
        align-items: center;
        flex-direction: row;
      }

      &-name {
        display: flex;
        align-items: center;

        &-skeleton {
          @include spacing(margin-left, xs);
        }

        &-warning-info {
          text-align: start;
          @include spacing(border-radius, 4);
          @include spacing(padding, xxs);
          font-style: italic;
        }
      }

      &-name,
      &-delegate {
        flex: 1;
      }

      &-delegate-participant {
        display: flex;
        align-items: center;
        @include spacing(border-radius, 4);
        @include spacing(padding, 4);
        @include spacing(margin-right, s);
      }

      &-actions {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        @include breakpoint(m) {
          flex-basis: 370px;
          flex-direction: row;
          width: inherit;
          align-items: center;
        }

        .button {
          @include spacing(margin, 4);
          @include spacing(min-height, xl);
          @include breakpoint(m) {
            flex: 1;
            @include spacing(min-width, 100);
          }
        }

        &-updating {
          position: absolute;
          display: flex;
          align-items: center;
          @include spacing(padding-right, xs);
          @include spacing(height, 56);
          @include spacing(width, 328);
          @include spacing(border-radius, 8);
          @include spacing(margin-left, l);
          @include var(background-color, update-layer-background-color);
        }
      }

      .information-box {
        @include spacing(padding, 10, xs);
        @include spacing(margin, 4);
      }
      .deactivated {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
}
