@import "../../../utils";

.terms-of-use {
  width: 100vw;
  overflow: auto;
  @include spacing(margin-top, 104);
  @include spacing(padding, 0, xxs, m);

  @include breakpoint(m) {
    flex: 1;
    width: 100%;
    @include spacing(padding, m, xl);
    @include spacing(margin-top, 0);
  }

  &-title {
    text-align: center;
    @include spacing(margin, xs);
  }

  &-edit-control{
    display: flex;
    justify-content: space-between;

    &-buttons{
      display: flex;

      button {
        @include spacing(margin-right, xxs);
      }
    }
  }

  &-container {
    @include spacing(padding, 0, 0, s);
    @include breakpoint(m) {
      @include spacing(padding, 0, xs, s);
    }

    &-headline {
      @include spacing(margin-bottom, s);
    }

    .version-erstellen-button {
      @include spacing(margin-top, xs);
    }
  }

   .versions-list-title {
    @include var(color, hint-color);
    @include spacing(margin, s, 0, xxs, 0);
  }

  .versions-verlauf-liste{
    @include spacing(margin, s, 0, 0, 0);
    @include var(background-color, secondary-background-color);
  }

  .wrapper-class {
    min-height: 500px;
    border-width: 4px;
    border-style: solid;
    @include var(border-color, button-border-color-low);
    @include var(background-color,  secondary-background-color);
    @include spacing(margin-top, xs);
    @include spacing(padding, xs);
  }

  .editor-buttons div{
    right: 1%;
  }

  .editor-class {
    min-height: 450px;
    border-width: 1px;
    border-style: solid;
    @include var(border-color, button-border-color-low);
    @include spacing(padding, xs);
    @include var(background-color, secondary-background-color);
    }

  .toolbar-class {
    border-width: 1px;
    border-style: solid;
    @include var(border-color, button-border-color-low);
  }

  .editor-buttons-wrapper {
    display: flex;
    align-items: center;

    h3 {@include spacing(margin-right, s);
    }
    button {
      @include spacing(margin-right, xxs);
    }
  }
}