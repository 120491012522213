@import "../../../utils";

.messages-list-page {
  @include elevation;
  @include spacing(border-radius, 4);
  @include var(background, secondary-background-color);
  @include spacing(margin, 72, 8, 64);
  display: flex;

  .message-details-not-selected {
    display: none;
    @include breakpoint(s) {
      display: flex;
    }
  }
  &-selected {
    .messages-list-page-right {
      transform: translateX(-360px);
      min-width: 100%;
      overflow: auto;
      @include breakpoint(xs) {
        transform: translateX(-100%);
      }
    }
    @include breakpoint(s) {
      .messages-list-page-right {
        transform: inherit;
        min-width: inherit;
      }
    }
  }

  height: calc(100vh - 184px);

  @include breakpoint(m) {
    height: calc(100vh - 160px);
    margin: inherit;
  }

  &-left {
    display: flex;
    flex-direction: column;
    @include spacing(min-width, 360);
    @include spacing(padding-bottom, xs);
    @include breakpoint(xs) {
      min-width: 100%;
    }
    @include breakpoint(s) {
      @include spacing(min-width, 360);
      @include spacing(max-width, 360);
    }
    @include breakpoint(l) {
      @include spacing(min-width, 450);
    }
  }

  &-right {
    @include var(background, secondary-background-color);
    transition: transform 0.5s ease-in-out;
    flex: 1;
    max-height: calc(100vh - 132px);
    @include elevation;
    @include spacing(border-radius, 4);
    @include breakpoint(s) {
      max-width: calc(100% - 360px);
    }
    @include breakpoint(l) {
      max-width: calc(100% - 450px);
    }
  }
}
