@import "../../utils";

.dashboard {
  width: 100vw;
  position: relative;
  @include spacing(margin-top, 116);
  @include spacing(padding, 0, xxs, m);

  @include breakpoint(m) {
    @include spacing(margin-top, 104);
    flex: 1;
    width: 100%;
    @include spacing(padding, m, xl);
    margin-top: 0;
  }

  &-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
    max-width: 1600px;
    margin: auto;
    @include spacing(padding, 0, 0, 20);
    @include breakpoint(l) {
      @include spacing(padding, 0, 0, 20);
      flex-direction: row;
    }

    &-left {
      flex: 1;
      width: 100%;
    }
    &-right {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      @include breakpoint(l) {
        @include spacing(padding-left, 24);
        @include spacing(top, 16);
        position: sticky;
        display: block;
        flex-basis: 475px;
        max-height: 101vh;
        overflow: auto;
      }

    }
  }
}
