@import "../../utils";

.management-dialog-description-container {
  display: flex;
  flex-direction: column;
  text-align: start;

  > span {
    @include spacing(margin-bottom, xs);
  }

  >label > input {
  appearance: none;
  @include var(background-color, input-background-color);
  @include spacing(border-radius, 4);
  @include spacing(padding, xs);
    text-transform: uppercase;
  border: none;
  flex: 1;
  width: 100%;


    &:focus{
      @include var(outline-color, input-focus-color);
      @include var(background-color, input-active-background-color);
    }
}
}