@import "../../utils";

.agenda-list {

  display: flex;
  flex-direction: column;
  counter-reset: section;


  @include breakpoint(m) {
    @include spacing(padding, 0, s);
  }
}