@import "../../../utils";

.object-document-upload-details-fields{
  @include var(background, agenda-list-item-color);

  @include spacing(padding, xs);
  display: flex;
  flex-direction: column;
  text-align: left;
  @include breakpoint(s) {
    @include spacing(width, 500);
  }
  @include breakpoint(m) {
    @include spacing(width, 640);
  }

  &.hasError {
    @include var(border-color, form-field-error-color);
    border-width: thin;
    border-style: solid;
  }

  &:first-child {
    @include spacing(border-radius, 8, 8, 0, 0);
  }

  &:last-child {
    @include spacing(border-radius, 0, 0, 8, 8);
  }

  &:not(:last-child) {
    border-bottom-style: solid;
    border-bottom-width: thin;
    @include var(border-color, border-color);

    &.hasError {
      @include var(border-color, form-field-error-color);
      border-width: thin;
      border-style: solid;
    }
  }

  &-error {
    @include var(color, form-field-error-color);
    @include spacing(font-size, 12);
    @include spacing(margin, xxs, 0);
  }

  &-header {
    display: flex;
    align-items: center;

    &-name {
      flex: 1;
      @include var(color, tab-color);
    }

    &-size {
     @include var(color, hint-color);
      @include spacing(font-size, 12);
      @include spacing(margin, 0, xxs, 0, 0);
    }

    .icon-button {
      box-shadow: none;
      border: none;
      @include spacing(width, s);
      @include spacing(height, s);
    }
  }

  &-permissions {
    display: flex;
    flex-direction: column;

    @include breakpoint(s) {
      flex-direction: row;
    }

    &-label {
      @include spacing(margin-top, s);
    }

    .checkbox-field {
      @include spacing(margin-right,s);
      @include spacing(margin-top,4);
    }
  }
}
