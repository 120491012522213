@import "../../utils";

.terms-of-use-user-list-item {
  display: flex;
  align-items: center;

  @include spacing(padding, xs, xxs, xs, xxs);
  @include var(background-color, agenda-list-item-color);
  @include boxShadow(0, 1px, 0, 0);
  @include spacing(min-width, 400);

  @include breakpoint(m) {
    @include spacing(padding, xs, xxs, xs, m);
  }

  &-name {
    flex: 1;
    @include spacing(min-width, 150);
    @include breakpoint(m) {
      @include spacing(min-width, 100);
    }
  }

  &-datum {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include spacing(max-width, 250);
    @include spacing(min-width, 150);
    @include spacing(padding, 0, s);

    @include breakpoint(m) {
      @include spacing(min-width, 80);
      justify-content: end;
    }
  }

  &-version {
    text-align: center;
    justify-content: flex-end;
    display: flex;
    @include spacing(min-width, 80);
  }
}