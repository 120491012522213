@import "../../utils";

.invitation-dialog-wrapper {
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: center;
  font-size: 14px;
  @include spacing(padding-top, xs);

  @include breakpoint(s) {
   text-align: center;
    @include spacing(padding-top, 0);
  }

  .invitation-dialog-question-wrapper {
    @include spacing(margin-bottom, s);
  }

  .invitation-dialog-question {
    max-width: 470px;
    display: flex;
    justify-content: flex-start;
    @include spacing(margin, xxs, 0);
    @include var(color, hint-color);
  }

  .invitation-dialog-info-text {
    @include spacing(margin-top, xs);
    @include spacing(padding, xs);
    @include var(background, navigation-background-active);
    @include var(color, primary-color);
    @include spacing(margin-bottom, 0);
    border-radius: 3px;
    display: flex;
    text-align: start;

    @include breakpoint(s) {
      @include spacing(margin-bottom, xs);
    }
  }
}
