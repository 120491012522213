@import "../../utils";

.user-online-state-badge {
    display: block;
    @include spacing(width, xs);
    @include spacing(height, xs);
    @include var(border-color, secondary-background-color);
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;

    &-absent {
        @include var(background-color, icon-red);
    }

    &-online {
        @include var(background-color, icon-green);
    }

    &-locally {
        @include var(background-color, icon-blue);
    }

    &-delegated-online,
    &-delegated-locally {
        @include spacing(min-width, 13);
        @include spacing(min-height, 13);
        @include spacing(height, 13);
        @include spacing(width, 13);
        @include spacing(left, 22);
        @include spacing(top, 22);
        border-style: solid;
        border-width: 2px;
        @include var(border-color, button-background-red);
    }

    &-delegated-locally {
        @include var(background-color, icon-blue);
    }

    &-delegated-online {
        @include var(background-color, icon-green);
    }

    &-hint {
        display: block;
        position: absolute;
        @include spacing(padding, 3);
        @include spacing(bottom, -18);
        @include spacing(left, 16);
        @include spacing(border-radius, 4);
        @include var(background-color, secondary-color);
        @include var(color, disable-color);
        white-space: nowrap;
        transition: opacity 0.5s linear;
        user-select: none;
        z-index: 1;
        opacity: 0;
    }
}
