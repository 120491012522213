@import "../../utils";

.agenda-list-item-instructions {
  @include spacing(padding, xxs, s);
  @include var(background, agenda-list-item-color);
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-instructions-granted {
      text-align: start;
      @include var (color, icon-green)
    }

    &-instructions-not-granted {
      text-align: start;
      @include var (color,  icon-red)
    }
  }

  &:hover {
    @include elevation;
  }

  &:not(:last-child) {
    @include boxShadow(0, 1px, 0, 0);

    &:hover {
      @include elevation;
    }
  }

  .item-number {
    @include spacing(margin-right, xs);
    @include var(color, hint-color);
    font-weight: bold;
  }

  &-headline {
    @include var(color, secondary-color);
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    text-align: start;
  }

  &-info {
    @include var(color, hint-color);
    font-size: 13px;
    margin: 0;
    text-align: start;
    white-space: pre-wrap;
    word-break: break-word;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
  }

  &-disable-link {
    pointer-events: none;
  }
}