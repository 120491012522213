@import "../../utils";

.message-details-custom-fields {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  @include var(border-color, diver-color);
  @include spacing(padding, xs);

  &-collapsed {
    @include spacing(max-height, 56);
    overflow: hidden;
    .message-details-custom-fields-headline-icon {
      transform: rotate(-90deg);
    }
  }

  &-content {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  &-headline {
    @include var(color, hint-color);
    @include spacing(padding, 0, 0, xs);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &-icon-path {
      @include var(fill, hint-color);
    }
  }
  &-label {
    @include var(color, hint-color);
    @include spacing(padding, 0, 0, 4);
    @include spacing(font-size, 13);
  }
  &-value {
    @include spacing(padding, 0, 0, xs);
    white-space: pre-wrap;
  }
}