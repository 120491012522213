@import "../../utils";

.message-details {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &-content:not(:empty){
    flex: 1;
    display: flex;
    flex-direction: column;
     & > .message-details-documents {
       border-bottom-width: 1px;
       border-bottom-style: solid;
       @include var(border-color, elevation-color-low-opacity);
       @include spacing(padding, xs, xs, 0)
     }

  }
  &-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include spacing(padding, xs, s, xxs);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    @include var(border-color, elevation-color-low-opacity);
    @include breakpoint(l) {
      flex-direction: row;
    }

    &-state {
      flex-grow: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include spacing(padding-left, xxs);
    }

    &-participants {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &-info {
      display: flex;
    }
    h1 {
      word-break: break-all;
      @include spacing(font-size, 18);
      @include spacing(line-height, 32);
      vertical-align: middle;
    }

    &-number {
      @include var("color", message-number-color);
      @include spacing(margin-left, xxs);
      @include spacing(font-size, 13);
    }
  }

  &-contacts {
    @include spacing(padding, xs, s);
    overflow: auto;
    flex: 1;
    max-height: calc(100vh - 328px);
    @include breakpoint(m) {
      max-height: calc(100vh - 365px);
    }

    &-create {
      &-form {
        @include spacing(padding, l, s);

        &-actions {
          display: flex;
          justify-content: flex-end;
          @include spacing(padding, xs, 0);
        }
      }

      &-loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        &-icon {
          &-path {
            @include var(fill, disable-color);
          }
        }
      }
    }
  }

  &-not-selected,
  &-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    &-icon {
      &-path {
        @include var(fill, disable-color);
      }
    }
  }

  .file-upload-preview {
    max-height: 150px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    transition: max-height 1s ease-in-out;

    &-complete {
      max-height: 0;
    }

    &-item {
      border-right-style: solid;
      border-right-width: 1px;
      @include var(border-right-color, elevation-color-low-opacity);
    }

    &:empty {
      max-height: 0;
      box-shadow: none;
    }
  }
}
