@import "../../../utils";

.participant-delegation {
  width: 100%;
  position: relative;

  &-selected {
    display: flex;
    align-items: center;
  }
  &-change {
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    @include spacing(margin, 4);
    @include var(background-color, button-border-color-low);
  }
}

