@import "../../../utils";

.currency-input-field {

  &-label {
    display: block;
    @include spacing(padding, xxs, 0);
  }
  &-error {
    @include var(color, form-field-error-color);
  }

  &-symbol {
    display: inline-block;
    @include spacing(font-size, 18);
    @include spacing(width, 40);
    @include spacing(height, s);
    @include var(color, button-background-grey);
    text-align: center;
  }

  &-trail-symbol {
   .currency-input-field-symbol {
      border-left-style: solid;
      border-left-width: 1px;
      @include var(border-left-color, button-background-grey);
     transform: translateX(-40px);
    }
  }
  input {
    @include var(background-color, input-background-color);
    @include spacing(border-radius, 4);
    @include spacing(padding, xs, 40, xs, xs);
    border: none;
    flex: 1;
  }
  input:focus {
    @include var(outline-color, input-focus-color);
    @include var(background-color, secondary-background-color);
  }
}