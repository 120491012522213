@import "../../utils";

.tab {
  flex: 1;
  position: relative;
  @include spacing(padding, xs);
  @include spacing(font-size, xs);
  @include var(color, tab-color);
  @include spacing(border-radius, 4, 4 ,0, 0);
  @include var(border-color, disable-color);
  @include spacing(border-width, 0.5);
  border-style: none solid none solid;
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  &:first-child {
    border-left-style: none;
  }

  &:last-child {
    border-right-style: none;
  }

  &-active {
    @include var(background-color, secondary-background-color);
    font-weight: 500;
    .tab-indicator {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      @include spacing(border-radius, 4, 4 ,0, 0);
      border-top-width: 4px;
      border-top-style: solid;
      @include var(border-top-color, navigation-color-active);
    }

    &-account {
      border-width: 1px;
      border-style: none solid none solid;
      @include var(border-color, disable-color);
    }
  }

}
