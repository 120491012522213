@import "../../../utils";

.agenda-item-proposition-voting-control-form {
  flex: 1;

   &-buttons-wrapper {
     flex-direction: column;
     justify-content: flex-start;
     align-items: flex-end;
     @include spacing(margin-bottom, s);

     @include breakpoint(s) {
       display: flex;
       flex-direction: row;
       justify-content: flex-end;
       flex-wrap: wrap;
       @include spacing(margin-bottom, xs);
       @include spacing(margin-top, s);
     }
   }

  .resolution-points {
    @include breakpoint(s) {
      min-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .simple-voting-wrapper {
    display: flex;
    flex-direction: column;
    @include spacing(margin-top, s);

    @include breakpoint(s) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  &-button {
    @include spacing(margin, 0, s,0,0);
    max-width: 340px;

    @include breakpoint(l) {
      @include spacing(margin-top, 0);
    }
  }

  .simple-voting-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include spacing(margin-right, l);


    @include breakpoint(l) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .number-input-field-error {
      max-width: 200px;
    }
  }

  label {
    display: flex;
    @include spacing(margin-right, s);
    @include spacing(margin-top, s);

    input {
      min-width: 50px;
      max-width: 120px;

      @include breakpoint(l) {
        min-width: 50px;
      }
    }
    span {
      min-width: 100px;
      max-width: 180px;
      @include spacing(margin-right, xxs);

      @include breakpoint(l) {
        min-width: 0;
        @include spacing(margin-right, 0);
      }
    }
  }

  &-text {
    flex: 1;
    font-weight: 600;
  }

  &-time {
    @include spacing(padding, xxs);
    @include spacing(border-radius, 4);
    @include var(background-color, input-background-color);
    @include var(color, list-number-color);
    display: flex;
    align-items: center;
    @include spacing(font-size, 14);
    line-height: 1.29;
    justify-content: space-evenly;

    &-input {
      background: transparent;
      border: none;
      @include spacing(padding, xxs);
      @include spacing(margin-right, xxs);
      border-right-width: 1px;
      border-right-style: solid;
      @include var(border-right-color, input-border-color);
      @include var(color, secondary-color);
      @include breakpoint(m) {
        @include spacing(width, l);
      }
    }
  }

  &-start-voting {
    @include spacing(margin-top, xxs);
    @include breakpoint(m) {
      @include spacing(margin-left, m);
      @include spacing(margin-top, 0);
    }
  }
}
