@import "../../utils";

.user-settings-management-container {

  .user-settings-management-container-list {
    display: flex;
    align-items: start;
    flex-direction: column;
    @include spacing(margin-left, xs);

    @include breakpoint(s) {
      flex-direction: initial;
    }

   .management-list-info {
     max-width: 100%;
     display: flex;
     flex-direction: column;
     @include spacing(margin-right, xxs);

     @include breakpoint(s) {
       max-width: 33%;
     }

     h3 {
       @include spacing(margin-bottom, xxs);
     }
   }

    .disable {
      @include var(color, hint-color);
    }

    .management-list {
      width: 25%;
      @include spacing(margin-left, xs);
    }
  }
}