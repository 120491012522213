@import "../../utils";

.api-error {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
  @include breakpoint(s) {
    margin-top: 20vh;
  }

  &.isUnauthorized {
    margin-top: 0;
  }
  &-title {
    text-align: center;
    @include spacing(margin, xl, 0, 0);
  }

  &-image {
    width: 80%;

    @include breakpoint(xl) {
      max-width: 33vh;
      margin: auto;
    }
  }

  &-details {
    @include spacing(margin, xs);
    width: 100%;
    display: flex;
    flex-direction: column;

    summary {
      @include spacing(padding, xs);
      cursor: pointer;
    }


    code {
      @include var(background, shadow-color);
      @include var(color, hint-color);
      display: block;
      @include spacing(padding, xs);
    }

  }
}