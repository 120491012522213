@import "../../utils";

.legal-notice-page-container {
  flex: 1;
  width: 100vw;
  @include spacing(margin-top, 104);
  @include spacing(padding, 0, xxs, m);

  @include breakpoint(m) {
    flex: 1;
    width: 100%;
    @include spacing(padding, m, xl);
    margin-top: 0;
  }

  &-info {
    display: flex;
    flex-direction: column;
    border-radius: 0.25rem;
    overflow: auto;
    justify-content: center;
    @include spacing(padding, s, xs, s, xs);
    @include var(background, secondary-background-color);

    @include breakpoint(m) {
      display: flex;
      justify-content: space-between;
    }

    &-title {
      font-weight: bold;
      @include spacing(padding-bottom, s);
    }

    .company-name {
      @include spacing(margin-bottom, s);
    }

    .company-contact {
      @include spacing(margin-bottom, s);
    }

    &-business-data, &-link {
      @include spacing(margin-bottom, s);
    }

    &-resources {
      margin: 0;

    }

    a {
      text-decoration: none;
      @include var(color, information-color);
      @include spacing(padding-left, xxs);
    }
  }
}