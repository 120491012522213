@import "../../../utils";

.agenda-item-proposition-voting {
  @include spacing(padding, 0, 0, xs, 0);

  &-action {
    display: flex;
    flex-direction: column;
    @include spacing(padding, xs, 0);
    @include breakpoint(m) {
      flex-direction: row;
    }

    .button {
      @include spacing(margin-bottom, xs);
      @include breakpoint(m) {
        @include spacing(width, 160);
        @include spacing(margin-right, s);
        @include spacing(margin-bottom, 0);
      }
    }
  }

  &-information {
    @include spacing(padding, 4, 4);
    @include var(color, hint-color);

    &-1, &-2, &-3 {
      display: inline-block;
      @include spacing(margin-right, 4);
    }

    &-1 {
      @include var(color, button-background-green);
    }
    &-2 {
      @include var(color, button-background-red);
    }
    &-3 {
      @include var(color, button-background-grey);
    }
  }
}

.agenda-item-proposition-information {
  display: flex;

  &-value {
    margin-inline-start: 0;
  }

  &-title {
    @include var(color, hint-color);
    @include spacing(min-width, 100);
  }
}