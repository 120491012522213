@import "../../../utils";

.agenda-item-update-form {
  display: flex;
  flex-direction: column;

  .agenda-item-form-read-only-wrapper {
    width: 100%;
    .info-text-wrapper {
      div {
        padding: 0;
        border: none;
      }
    }
  }

  &-edit-attachments {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    @include var(border-top-color, input-background-color);
    @include spacing(padding, xxs, 0);
  }

  .file-input-field-container {
    margin: 0;
    padding: 0;
    outline: none;
    .file-input-field-icon {
      &-path {
        @include var(fill, secondary-color)
      }
    }
  }

  &-readonly {
    flex-direction: row;
  }

  &-field {
    flex: 1;
    display: flex;
    label {
      flex: 1;
      textarea {
        resize: vertical;
        min-height: 8em;
      }
    }
  }

  div.agenda-item-update-form-field {
    @include spacing(padding, 0 , xs, 0, 0);
    white-space: pre-wrap;
    word-break: break-word;
    text-align: justify;
  }

  &-actions {
    @include spacing(padding, xxs, 0 , xs);
    display: flex;

    .button:first-child {
      @include spacing(margin-right, xs);
    }
  }

  .icon-button {
    @include spacing(margin-right, m);
  }
}