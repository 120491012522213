@import "../../utils";

.bulletin-board-post-create-post {
  .hidden-button {
    display: none;
  }
  display: flex;
  @include spacing(border-radius, 4);

  &-user {
    @include spacing(padding-top, xxs);
    @include spacing(padding-right, 4);
  }
  &-form {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-end;
  }

  &-actions {
    display: flex;
    flex-direction: column;
    width: 100%;
    .button {
      width: 100%;
    }
    .selected-objects {
      display: block;
      @include spacing(margin-top, xxs);
      @include spacing(padding, 0, xxs);
      @include spacing(line-height, 24);

    }
    @include breakpoint(s) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: inherit;
      .button:last-child {
        @include spacing(margin-left, xxs);
      }
      .button {
        width: inherit;
      }
      .selected-objects {
        display: block;
        @include spacing(margin-top, xxs);
        @include spacing(padding, 0, xxs);
        @include spacing(line-height, 48);

      }
    }
  }
  &-text-length,
  &-upload {
    display: none;
  }
  &-fields {
    flex: 1;
    position: relative;
    width: calc(100% - 16px);
    @include spacing(border-radius, 0, 4, 4, 4);
    &:before {
      content: " ";
      display: block;
      position: absolute;
      @include spacing(width, 0);
      @include spacing(height, 0);
      @include var(border-right-color, input-background-color);
      @include var(border-top-color, input-background-color);
      @include spacing(border-width, 9);
      border-style: solid;
      border-left-color: transparent;
      border-bottom-color: transparent;
      top: 1px;
      left: -18px;
    }

    &.hasFocus {
      @include var(outline-color, primary-color);
      outline-style: solid;
      outline-width: 2px;
      @include spacing(padding, 0, xxs);
      &:before {
        @include var(border-right-color, primary-color);
        @include var(border-top-color, primary-color);
        @include spacing(border-width, 11);
        left: -22px;
        top: -2px;
      }

      .bulletin-board-post-create-post-text-length {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-top-width: thin;
        border-top-style: solid;
        @include var(border-top-color, input-background-color);
        @include spacing(padding, xxs, 0);
        @include var(color, hint-color);
        @include spacing(font-size, 12);
        @include spacing(line-height, s);

        &.oversizing {
          @include var(color, form-field-error-color);
        }
      }

      .bulletin-board-post-create-post-upload {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include var(border-top-color, input-background-color);
        @include spacing(padding, xxs, 0);
      }

      .file-input-field-container {
        margin: 0;
        padding: 0;
        outline: none;
        .file-input-field-icon {
          &-path {
            @include var(fill, secondary-color)
          }
        }
      }
      .textarea-field textarea {
        @include var(background-color, secondary-background-color)
      }
    }

    textarea:active:not([readonly]),
    textarea:focus:not([readonly]),
    textarea:focus-visible:not([readonly]), {
      outline: none;
    }
  }
  &-field {

    &.textarea-field textarea {
      @include spacing(border-radius, 0, 4, 4);
      @include spacing(padding, 9, xs);
      @include spacing(margin-top, 1);
    }
  }
  .button {
    @include spacing(margin-top, xxs);
  }

  &-multi-title {
    display: flex;
    justify-content: space-between;
  }
  .dialog-description {
    max-height: 55vh;
    overflow: hidden;
  }
}
