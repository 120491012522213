@import "../../../utils";

.send-invitation-dialog-info-wrapper {
  display: flex;
  flex-direction: column;

  .invitation-description {
    max-width: 100%;
    display: flex;
    text-align: justify;
    @include spacing(margin-bottom, s);
    @include var(color, hint-color);
    justify-content: center;
  }

  .input-field-wrapper {
    width: 100%;
    min-height: 80px;
    display: flex;
    flex-direction: row;
    position: relative;

    .text-input-field {
      width: 100%;
    }

    .menu{
      position: absolute;
      right: 0;
    }
  }

  .icon-button {
    width: 100%;
    height: 100%;
    border: none;
    box-shadow: none;
    @include spacing(padding-bottom, 12);
    @include spacing(padding-top, 12);
    @include var(background-color, input-background-color);
  }
}