@import "../../../utils";

.event-log-wrapper {
  position: relative;
  @include spacing(padding, m, s);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: auto;
  @include breakpoint(m) {
    width: inherit;
  }

  .pdf-create-component-wrapper {
    display: flex;
    justify-content: flex-start;

    @include breakpoint(s) {
      justify-content: flex-end;
    }
  }
  .event-log-headline {
    flex: 1;
    @include spacing(font-size, 18);
    @include spacing(margin-bottom, s);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100vw - 160px);
    @include spacing(margin-right, s);
    @include breakpoint(m) {
      max-width: calc(100vw - 542px);
      width: inherit;
    }
  }

  .event-log-item-list-wrapper {
    display: flex;
    flex-direction: column;

    span {
      @include spacing(margin-right, s);
    }

    .event-log-item-time {
      @include var(color, hint-color);
      min-width: 200px;
    }

    .event-log-item {
      display: flex;
      flex-direction: column;
      @include spacing(margin-bottom, s);

      @include breakpoint(s) {
          flex-direction: row;
      }
    }

    .color-proposition-voting-started, .color-proposition-voting-ended {
      @include var(color, message-state-in-progress-color);
    }

     .color-participant-left-meeting, .color-proposition-voting-repeated {
      @include var(color, icon-red);
    }

    .color-participant-delegated-voting-eligibility, .color-voting-eligibility-changed-by-employee {
      @include var(color, message-state-open-color);
    }
  }

  .etv-pdf-link {
    text-decoration: none;

    .etv-pdf-button {
      @include var(background, primary-color);
      @include var(color, secondary-background-color);
    }
  }
}