@import "../../utils";

.details {
  @include spacing(margin, xxs, xs);
  @include spacing(padding, xs);
  @include var(background-color, agenda-list-item-color);
  @include var(color, secondary-color);
  &-headline {
    display: flex;
    cursor: pointer;
    align-items: center;

  }
  &-action {
    flex: 0;

    &-open {
      cursor: pointer;
      transform: rotate(-90deg);
      transition: transform 0.25s ease-in-out;
    }
  }

  &-summary {
    flex: 1;
    @include spacing(font-size, xs);
  }

  &-content {
    &-inner {
      @include spacing(padding-top, xs);
      white-space: pre-wrap;
    }
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
  }

  &-open {
    .details-action-open {
      transform: rotate(0deg);
    }
    .details-content {
      max-height: 1000vh;
    }
  }
}
