@import "../../../utils";

.agenda-item-proposition-voting-qualification-rules-update-form {
  @include spacing(border-radius, 4);
  @include spacing(padding, xs);
  @include spacing(margin-top, xs);
  background-color: #eef1f1;
  border: solid 1px;
  @include var(border-color, border-color);
  &-button {
    align-self: flex-start;
    @include spacing(margin, s, s, 0, 0);
  }


  hr {
    @include var(background-color, diver-color);
    @include spacing(height, 1);
    @include spacing(margin, xs, 0);
    border: none;
  }

  .deactivated {
    flex-direction: row;
  }

  .radio-button-wrapper:nth-child(2) {
    border-right: solid 1px;
    @include var(border-color, diver-color);
    @include spacing(margin-right, xs);
  }

  &-row {
    flex: 1;
    display: flex;
    align-items: baseline;

    section {
      margin: 0;
    }

    &:not(:nth-child(2)) {
      justify-content: space-between;
        section {
          &:first-child {
            flex: 1;
            max-width: 75%;
            min-width: 75%;
          }

          &:last-child {
            flex: 1;
            max-width: 20%;
          }
        }
    }

    &.small-text, .radio-label, .checkbox-field-label, .select-field-label {
      @include spacing(font-size, 14);
      @include spacing(padding-bottom, xxs);
    }

    &.hidden-row {
      display: none;
    }

    .hidden-column {
      display: none;
    }
  }

  &-actions {
    @include spacing(padding, xxs, 0);
    display: flex;

    .button:first-child {
      @include spacing(margin-right, xs);
    }
  }
}
