@import "../../../utils";

.voting-eligibility-dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include var(background-color, input-background-color);
  @include spacing(border-radius, 4);
  @include spacing(padding, 4);
  position: relative;

  &.readonly {
    pointer-events: none;
    opacity: 0.4;
  }

  &-input {
    flex: 1;
    border: none;
    background-color: transparent;
    @include spacing(padding, 8, 0);
    @include spacing(margin, 0, 4);
    max-width: calc(100% - 86px);
  }
  &-list {
    z-index: 1000;
    &-open {
      display: block;
      @include elevation;
      @include spacing(top, 48);
      @include spacing(border-radius, 4);
      z-index: 1;
    }

    &-top {
      top: inherit;
      @include spacing(top, 48);
    }
    @include var(background-color, secondary-background-color);
    display: none;
    position: absolute;
    max-height: 246px;
    overflow: auto;
    cursor: pointer;
  }

  &-option {
    display: flex;
    align-items: center;
    @include var(background-color, agenda-list-item-color);
    @include boxShadow(0, 1px, 0, 0);
    @include spacing(padding, xxs);
    .profile-picture {
      @include spacing(margin-right, xxs)
    }
  }
  &-option:hover {
    @include var(background-color, navigation-background-active);
  }
  &-deselect {
    cursor: pointer;
    border-radius: 50%;
    font-weight: 600;
    text-align: center;
    @include var(background-color, button-background-grey);
    @include var(color, button-color-red);
    @include spacing(line-height, s);
    @include spacing(font-size, 11);
    @include spacing(min-width, s);
    @include spacing(max-width, s);
    @include spacing(height, s);
    @include elevation;
    flex: 1;
  }
}
