@import "../../utils";

.bulletin-board-post-list {
  position: relative;
  @include elevation;
  &-header {
    display: flex;
    flex-direction: column;
    @include spacing(padding, xs, s, s, s);
    border-bottom: solid;
    border-bottom-width: 1px;
    @include var(border-bottom-color, diver-color);
    @include var(background-color, secondary-background-color);
    h3 {
      @include var(color, primary-color);
      @include spacing(margin-bottom, s);
    }
    @include spacing(border-radius, 4, 4, 0, 0);
  }
  &-items {
    @include var(background-color, secondary-background-color);
    @include spacing(border-radius, 0, 0, 4, 4);
  }
}
