@import "../../utils";

.etv-participants-list-item {
  display: flex;
  @include spacing(padding, xxs);
  @include var(background-color, agenda-list-item-color);
  @include boxShadow(0, 1px, 0, 0);
  align-items: center;

  &-name-object-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-basis: 30%;
    min-width: 30%;

  }

  &-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-basis: 70%;
    min-width: 70%;
  }


  &-unit {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-basis: 15%;
    min-width: 15%;
    @include spacing(padding, 0, xs);
    @include spacing(margin-left, xl);
  }
  &-delegate {
    display: flex;
    align-items: center;
    flex-basis: 20%;
    min-width: 20%;
  }

  &-attendance {
    display: flex;
    align-items: center;
    flex-basis: 15%;
    min-width: 15%;

    &-loading {
      @include spacing(margin-right, xs);
    }

    &-check,
    &-decline {
      display: flex;
      @include spacing(height, xs);
      @include spacing(min-width, xs);
      @include spacing(line-height, xs);
      align-items: center;
      justify-content: center;
      @include spacing(font-size, 8);
      font-weight: 800;
    }

    &-check {
      @include var(color, secondary-background-color);
    }

    &-decline {
      @include var(color, secondary-background-color);
    }

    .user-online-state-badge,
    .participant-attendance-state-badge{
      @include spacing(margin-right, xs);
      @include spacing(flex-basis, m);
    }
  }

  &-actions {
    text-align: center;
    flex-basis: 15%;
    min-width: 15%;
  }

  .profile-picture {
    @include spacing(margin-right, xs);
  }

}
