@import "../../utils";

.objects-page-actions-button-dialog-description-wrapper {
   max-width: 500px;

  .description-text {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .invitations-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include spacing(margin-top, s);

    &-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include spacing(margin-left, s);
    }
  }

}
