@import "../../utils";

.etv-pin-dialog-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include var(color, hint-color);

  .etv-pin-dialog-description {
    max-width: 350px;
    @include spacing(padding-left, xxs);
  }

  .text-input-field  {
    display: flex;
    align-items: center;
    flex-direction: column;

    input {
      font-size: 28px;
      font-family: monospace;
      width: 156px;
      height: 50px;
      letter-spacing: 18px;
      padding: 0;
      padding-left: 16px;
    }

    /* Für Chrome, Safari, Edge */
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Für Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .etv-pin-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include spacing(margin, xs, m);

    .etv-pin-input {
      position: relative}

    .etv-pin-input:before {
      position: absolute;
      content: "";
      left: 0;
      right: 16px;
      height: 10px;
      bottom: 10px;
      background: linear-gradient(90deg,
              transparent 5%, transparent 10%, /* Abstand vor dem ersten Strich */
              dimgrey 10%, dimgrey 25%, /* Erster Strich */
              transparent 15%, transparent 35%, /* Abstand zum zweiten Strich */
              dimgrey 35%, dimgrey 50%, /* Zweiter Strich */
              transparent 40%, transparent 60%, /* Abstand zum dritten Strich */
              dimgrey 60%, dimgrey 75%, /* Dritter Strich */
              transparent 65%, transparent 85%, /* Abstand zum vierten Strich */
              dimgrey 85%, dimgrey 100%, /* Vierter Strich */
              transparent 90%, transparent 100%)
      0 90% / 100% 2px no-repeat; /* Platzierung des Hintergrunds */
      opacity: 0.2;

    }

    span {
      @include spacing(margin-top, xs);
      font-size: small;
    }
  }

}
