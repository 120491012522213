@import "../../utils";

.message-list-header {
  @include spacing(padding, xxs);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include boxShadow(0, 1px, 0, 0);


  .message-list-container-selector {
    @include var(background-color, secondary-background-color);
    @include spacing(padding, xxs, xxs, 0);
    display: none;
    @include breakpoint(s) {
      display: block;
    }
    @include breakpoint(m) {
      display: block;
      @include spacing(padding, xs, xs, 0);
    }
  }

  &.manager {
    justify-content: flex-start;
  }
}
