@import "../../../utils";
.dialog-crop {
  &-image {
    width: 100%;
    height: calc(100vw / 2);

    @include breakpoint(m) {
      width: auto;
      height: auto;
    }
  }
}
