@import "../../utils";

.home-page-container {
  flex: 1;
  width: 100vw;
  @include spacing(margin-top, 104);
  @include spacing(padding, 0, xxs, m);

  @include breakpoint(m) {
    flex: 1;
    width: 100%;
    @include spacing(padding, m, xl);
    margin-top: 0;
  }

  &-info {
    flex: 1;
    border-radius: 0.25rem;
    overflow: auto;
    max-height: calc(100vh - 150px);
  }
  .management-objects-container {
    width: 100%;
    @include breakpoint(m) {
      flex: 1;
      max-width: 1600px;
      margin: auto;
    }

    > h2 {
      @include spacing(margin, 0, xxs);
    }

    .tittle-sort-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include spacing(margin, 0, xxs);
    }
  }

  .home-page-header-actions-group-button {
    background: none;
    border: none;
    box-shadow: none;
    @include spacing(margin-right, xxs);
    @include breakpoint(m) {
      margin: 0;
    }
    .button-icon {
      margin: 0;
      display: inherit;
      @include var(stroke, secondary-color);
      @include breakpoint(m) {
        display: none;
      }
    }
    .button-icon-trailing {
      margin: 0;
      width: 15px;
      @include var(fill, secondary-color);
    }

    .button-label {
      display: none;
      @include breakpoint(m) {
        display: inherit;
        font-weight: normal;
        @include spacing(padding-right, xxs);
        @include spacing(font-size, xs);
      }
    }
  }
}
