@import "../../utils";

.switch-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  @include spacing(margin-bottom, xs);

  .info-text {
    @include spacing(padding-top, xs);
  }
  .tgl {
    display: none;
    width: 10px;
  }

  .tgl-label {
    display: flex;
  }

  .tgl + .tgl-circle {
    outline: 0;
    display: block;
    width: 10px;
    height: 8px;
    position: relative;
    cursor: pointer;
    @include spacing(margin-right, m);
  }
  .tgl + .tgl-circle:after,
  .tgl + .tgl-circle:before {
    position: relative;
    display: block;
    content: "";
    width: 20px;
    height: 20px;
    @include spacing(bottom, 6);
  }
  .tgl + .tgl-circle:after {
    right: 10%;
  }
  .tgl + .tgl-circle:before {
    display: none;
  }
  .tgl:checked + .tgl-circle:after {
    left: 50%;
  }

  .tgl-input + .tgl-circle {
    min-width: 30px;
    border-radius: 7px;
    border-style: solid;
    border-width: 1px;
    @include var(border-color, secondary-background-color);
    @include spacing(padding, 2);
    @include var(background-color, disable-color);
  }
  .tgl-input[disabled] + .tgl-circle {
    min-width: 30px;
    border-radius: 7px;
    border-style: solid;
    border-width: 1px;
    @include var(border-color, secondary-background-color);
    @include spacing(padding, 2);
    @include var(background-color, shadow-color);
  }
  .tgl-input + .tgl-circle:after {
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    @include var(background-color, secondary-background-color)
  }
  .tgl-input:checked + .tgl-circle {
    @include var(background-color, button-background-green);
  }
  .tgl-input[disabled]:checked + .tgl-circle {
    min-width: 30px;
    border-radius: 7px;
    border-style: solid;
    border-width: 1px;
    @include var(border-color, secondary-background-color);
    @include spacing(padding, 2);
    @include var(background-color, icon-green-disabled);
  }
}
  .info-text {
    margin-left: 67px;
   @include var(color, hint-color);
    .email-notifications-info-text {
      display: inline-block;
      border-radius: 50%;
      @include spacing(width, xs);
      @include spacing(height, xs);
      @include spacing(margin, xxs, 0, xxs , xxs);
      @include spacing(line-height, xs);
      @include var(background-color, icon-blue);
      @include var(color, button-background-white);
      font-weight: 600;
      text-align: center;
      cursor: pointer;

      @include breakpoint(s) {
        position: inherit;
      }
      @include breakpoint(m) {
        position: inherit;
      }
      @include breakpoint(l) {
        @include spacing(margin-top, 0);
      }

      &:hover {
        .email-notifications-info-text-hint {
          opacity: 1;
          display: block;
        }
      }

      &-hint {
        display: none;
        position: absolute;
        transition: opacity 0.5s linear;
        user-select: none;
        z-index: 1;
        opacity: 0;
        max-width: 500px;
        @include spacing(padding, 3);
        @include spacing(top, 150);
        @include spacing(left, 0);
        @include spacing(right, 0);
        @include spacing(border-radius, 4);
        @include var(background-color, secondary-color);
        @include var(color, disable-color);

        @include breakpoint(s) {
          top: 90%;
          left: 40%;
          white-space: inherit;
        }

        &-list {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          align-items: flex-start;
          text-align: start;
          margin-left: 20px;
          margin-right: 15px;
          padding: 0;

          li {
            @include spacing(padding-bottom, 10);
          }
        }
      }
    }
}

