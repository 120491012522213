@import "../../utils";

.bulletin-board-post-list-item-comments {
  display: flex;
  flex-direction: column;

  &-count {
    display: flex;
    align-items: center;
    user-select: none;
    &.interactive {
      cursor: pointer;
    }
  }
  .icon {
    @include spacing(margin-right, xxs);
  }
  .icon-path {
    @include var(fill, message-number-color);
  }

  &-count {
    @include spacing(padding, xxs, 0);
  }
  &-list {
    @include spacing(padding, 0, xs);
    @include spacing(margin-left, l);
    @include spacing(border-radius, 4);
    @include var(background-color, input-background-color);
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 0.5s ease-in-out;
    &.open {
      @include spacing(margin-bottom, xs);
      max-height: 50vw;
      overflow-y: auto;
    }
  }


  &-list-item {
    position: relative;
    @include spacing(padding, xs, 0);
    &:not(:last-child) {
      border-bottom: solid;
      border-bottom-width: 1px;
      @include var(border-bottom-color, diver-color);
    }

    &-user {
      display: flex;
      justify-content: space-between;

      &-pinned {
          @include spacing(margin-right, xl);
      }
    }
  }

  &-list-item-text {
    @include spacing(padding, xs, 0, 0, l);
    white-space: pre-line;
    word-break: break-all;
  }
}
