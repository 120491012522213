@import "../../utils";

.agenda-list-item {
  @include spacing(padding, xxs, s);
  @include var(background, agenda-list-item-color);
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  position: relative;

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;


    @include breakpoint(m) {
      flex-direction: row;
      justify-content: space-between;
    }


    &-instructions-granted {
      text-align: start;
      @include var (color, icon-green)
    }

    &-instructions-not-granted {
      text-align: start;
      @include var (color,  icon-red)
    }
  }

  &:hover {
    @include elevation;
  }

  &:not(:last-child) {
    @include boxShadow(0, 1px, 0, 0);

    &:hover {
      @include elevation;
    }
  }

  .item-number {
    @include spacing(margin-right, xs);
    @include var(color, hint-color);
    font-weight: bold;
  }

  &-headline {
    @include var(color, secondary-color);
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    text-align: start;
  }

  &-info-wrapper {
    max-width: 80%;
  }

  &-info {
    @include var(color, hint-color);
    font-size: 13px;
    margin: 0;
    text-align: start;
    white-space: pre-wrap;
    word-break: break-word;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
  }

  &-disable-link {
    pointer-events: none;
  }

  .voting-info-wrapper {
    width: 15%;
    display: flex;
    justify-content: flex-start;
    .voting-info {
      font-size: 14px;
      &.progress {
        @include var(color,  message-state-open-color);
      }

      &.pending {
        @include var(color, hint-color);
      }

      &.ended {
        @include var(color, icon-green);
      }
    }
  }


}