@import "../../utils";

.menu {
  position: relative;
  @include var(color, secondary-color);
  &-button {
    cursor: pointer;
  }

  &-list {
    list-style: none;
    padding-inline-start: 0;
    margin: 0;
    @include spacing(max-height, 264);
    overflow-y: auto;
  }

  &-header {
    @include spacing(padding, xs);
    @include spacing(font-size, xs);
    @include var(border-bottom-color, diver-color);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    font-weight: 400;
    white-space: nowrap;
  }

  &-footer {
    @include spacing(padding, xxs);
    @include spacing(font-size, 12);
    @include var(border-top-color, diver-color);
    border-top-width: 1px;
    border-top-style: solid;
    text-align: center;
  }
  &-surface {
    display: none;
    position: absolute;
    @include elevation;
    @include spacing(border-radius, 4);
    @include spacing(top, 44);
    @include var(background-color, secondary-background-color);

    right: 0;
    z-index: 4;

    &-open {
      display: block;
      min-width: 180px;
    }
  }

  .menu-title {
    @include spacing(margin, xxs);
    @include var(color, hint-color);
    font-size: small;
    opacity: 0.7;
  }
}