@import "../../../utils";

.agenda-item-details {
  .agenda-item-details-skeleton {
    width: 50%;
    @include spacing(height, 19);
  }

  .meeting-item-attachments {
    display: flex;

    .meeting-attachment-item {
      @include spacing("margin", xs)
    }

    .meeting-attachment-item-name {
      max-width: 56px;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap: nowrap;
    }

    .file-upload-preview-item-complete {
      display: none;
    }
  }

  .dialog {
    .dialog-title {
      max-width: calc(100vh - 150px);
    }
  }

  .details-summary {
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;

    @include breakpoint(m) {
      flex-direction: row;
      align-items: baseline;
    }
    .proposition-summary-prefix {
      @include spacing(line-height, 38);
    }
    .proposition-update-form {
      flex: 1;
      @include spacing(margin, 0, xs, 0, 0);
      @include breakpoint(m) {
        @include spacing(margin, 0, xs, 0, xxs);
      }

      &-field {
        textarea {
          @include spacing(font-size, xs);
        }
        textarea[readonly] {
          @include spacing(line-height, 38);
        }
        textarea:not([readonly]) {
          @include spacing(padding, xxs);
        }
      }
    }
  }
}