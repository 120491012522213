@import "../../../utils";

.contact-person-card-dialog-background-layer {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  @include var(background-color, elevation-color-low-opacity);

  .contact-person-card-dialog-container{
    margin: auto;
    overflow: auto;


    min-width: calc(100% - 16px);
    max-width: calc(100% - 16px);

    @include breakpoint(s) {
      min-width: 540px;
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    z-index: 100;

    @include boxShadow(0, 4px, 14px, 0, dialog-shadow);
    @include var(background-color, secondary-background-color);
    @include spacing(border-radius, xxs);
    @include spacing(padding, xxs, xxs, s, xxs );

    .dialog-container-close {
      text-align: end;
      cursor: pointer;

      &-icon {
        @include var(fill, elevation-color-low-opacity);
        @include spacing(border-radius, 4);
        @include var(background-color, elevation-color-high-opacity);
      }
    }

    &-contacts {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include spacing(margin, xxs, 0);

      @include breakpoint(s) {
        flex-direction: row;
      }

      :nth-child(even) a,
      :nth-child(even) div{
        @include breakpoint(s) {
          justify-content: start;
        }
      }

      &-info {
        display: inline-table;
        width: 50%;
        align-items: center;
        @include var(color, hint-color);
        @include spacing(padding-left, xxs);

        a,
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          color: inherit;
          @include breakpoint(s) {
            justify-content: end;
          }
        }

        .center {
          justify-content: center;
        }

        &:nth-child(even) {
          @include breakpoint(s) {
            border-left: 2px solid;
            @include var(border-color, icon-grey);
            @include spacing(margin-left, xs);
          }
        }
      }
    }

    &-data {
      display: flex;
      flex-direction: column;

      .contact-person-cards-contacts-info {
        @include spacing(margin-top, xxs);
        a{
          text-decoration: none;
          color: inherit;
        }
      }
    }
  }
}

