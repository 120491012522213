@import "../../utils";

.employee-container {
  display: block;

  &-item {

    &-open {
      display: flex;
      justify-content: space-between;

      p {
        @include var(color, primary-color);
      }
    }

    &-info {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      @include var(color, secondary-color);
      @include spacing(padding, xs, xs, xs, xs);
      @include boxShadow(0, 2px, 0, 0);
      @include spacing(font-size, 14);
      width: 100%;

      &-email {
        display: flex;
        justify-content: flex-start;
        min-width: 600px;
      }

      &-title {
        display: flex;
        justify-content: flex-start;
        min-width: 200px;

        p {
          @include spacing(margin-left, s);
        }
      }

      &-headline-icon-down {
        width: 15px;
        height: 15px;
        @include var(fill, primary-color);
      }

      &-headline-icon-right {
        width: 15px;
        height: 15px;
        transform: rotate(-90deg);
        @include var(fill, hint-color);
      }

      &.open {
        display: flex;
        flex-direction: column;
        min-height: 80px;
        @include boxShadow(0, 2px, 0, 0);

        .title-container {
          display: flex;
          justify-content: flex-start;
        }
        .contact-info-card-wrapper {
          display: flex;
          flex-direction: column;
          align-items: start;

          @include breakpoint(s) {
            flex-direction: row;
            align-items: center;
          }

          .info-card {
            @include var(color, hint-color);
            @include spacing(margin-top, xxs);

            &-communication-data {
              @include spacing(margin-top, xxs);

              &-info {
                display: flex;
                justify-content: flex-start;
                @include spacing(margin-top, xxs);

                &-icon {
                  &-path {
                    @include var(fill, primary-color);
                  }
                }

                a,
                div {
                  display: flex;
                  align-items: center;
                  text-decoration: none;
                  @include var(color, primary-color);
                  @include breakpoint(s) {
                    justify-content: end;
                  }
                }
              }
            }
          }
        }

      }

      &:hover {
        @include elevation;
        .dashboard-right-container-item-contact {
          visibility: inherit;
        }
      }

      .permissions-container {
        cursor: auto;

        .permissions-container-title {
          @include spacing(margin, xs, l);
          @include var(color, hint-color);
        }

        .permissions-info-text {
          @include spacing(margin, xs, l);
        }

        .permissions-list {
          list-style: none;

          li {
            @include spacing(margin-bottom, xxs);
          }
        }
      }

    }
  }

}
