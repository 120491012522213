@import "../../../utils";

.etv-meetings {
  @include spacing(margin-top, 116);

  @include breakpoint(m) {
  @include spacing(margin-top, 104);
  }
  .is-loading {
    @include spacing(margin, xxl, 0);
  }

  @include breakpoint(m) {
    margin-top: 0;
  }
}
