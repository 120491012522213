@import "../../utils";

.management-facility-objects-table {
  @include var(background, secondary-background-color);
  @include elevation;
  @include spacing(border-radius, 4);
  &-header {
    display: flex;
    @include spacing(padding, s, xs);
    @include boxShadow(0, 1px, 0, 0);
    &-description {
      flex: 1;
    }

    &-id {
      flex-basis: 100px;
    }

    &-state {
      flex-basis: 200px;
    }

    &-user-entries {
      flex-basis: 150px;
    }

    &-description {
      flex-basis: 200px;
    }
    &-id,
    &-description,
    &-state {
      font-weight: 600;
      cursor: pointer;
      position: relative;
      @include spacing(padding-left, m);
    }

    &-id:before,
    &-description:before,
    &-state:before {
      content: ' ';
      position: absolute;
      width: 0;
      top: 12px;
      left: 8px;
      @include spacing(right, xxs);
      border: 8px solid transparent;
      @include var(border-top-color, disable-color);
      font-size: 2px;
    }

    &-id:after,
    &-description:after,
    &-state:after {
      content: ' ';
      position: absolute;
      left: 8px;
      top: -6px;
      width: 0;
      @include spacing(right, xxs);
      border: 8px solid transparent;
      @include var(border-bottom-color, disable-color);
      font-size: 2px;
    }

    &-id[aria-sort="ascending"]:before,
    &-description[aria-sort="ascending"]:before,
    &-state[aria-sort="ascending"]:before {
      @include var(border-top-color, button-color);
    }

    &-id[aria-sort="descending"]:after,
    &-description[aria-sort="descending"]:after,
    &-state[aria-sort="descending"]:after {
      @include var(border-bottom-color, button-color);
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    @include spacing(padding, xs);
  }

  &-body {
    max-height: 65vh;
    overflow: auto;
    @include breakpoint(m) {
      max-height: 75vh;
    }

    &-no-result {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include spacing(padding, xs);

      svg {
        opacity: 0.3;
      }
      &-headline {
        position: absolute;
        top: 25vh;
        @include spacing(padding, xs);
      }
    }
  }
  &-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include spacing(padding, 0, xs);
    text-decoration: none;
    @include var(color, secondary-color);
    &:not(:last-child) {
      @include boxShadow(0, 1px, 0, 0);

      &:hover {
        @include elevation;
      }
    }

    &-text-wrapper {
      min-width: 60%;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      margin-right: auto;
      text-decoration: none;
      @include var(color, secondary-color);
    }

    &-id,
    &-description,
    &-state {
      @include spacing(padding, xs, xs, xs, m);
    }


    &-description {
      flex: 1;
      min-width: 25%;
      @include spacing(padding-left, xxs);
    }

    &-id {
      @include spacing(padding-left, xxs);
      flex-basis: 100px;
    }

    &-state {
      flex-basis: 220px;
    }
    &-user-entries {
      flex-basis: 150px;
    }
    &-state,
    &-user-entries {

      .switch-wrapper {
        margin-bottom: 0;

        .tgl-label {
          @include var(color, icon-red);
        }
      }
    }

    &-state.online,
    &-user-entries.online {
      .switch-wrapper {
        .tgl-label {
          @include var(color, icon-green);
        }
      }
    }

  }
}
