@import "../../../utils";

.instruction-wrapper {
  display: flex;

  &.propositions {
    flex-direction: column;
    align-items: start;
  }

  .agenda-item-number {
    display: flex;
    justify-content: flex-start;
    width: 60px;
  }

  .instruction-vote {
    @include spacing(margin-left, xxs);
  }

  .proposition-info {
    display: flex;
    @include spacing(margin-left, xxs);
    @include spacing(margin-bottom, xxs);
  }

  .green {
    @include var(color, message-state-in-progress-color);
  }

  .red {
    @include var(color, icon-red);
  }

  .blue {
    @include var(color, icon-blue);
  }
}