@import "../../utils";

.documents-facility-object {
  @include var(background-color, secondary-background-color);

  &-list {
    max-height: calc(100vh - 320px);
    @include breakpoint(m) {
      max-height: calc(100vh - 282px);

    }
    overflow: auto;

    &-select,
    &-noresult {
      min-height: 50vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include spacing(padding, xs);

      h2 {
        @include spacing(padding-top, xs);
        text-align: center;
        max-width: 500px;
      }
    }

    .documents-category-wrapper {
      cursor: pointer;
      @include boxShadow(0, 1px, 0, 0);

      &:hover {
        @include elevation;
      }

      .category-documents-headline {
        @include var(color, hint-color);
        @include spacing(margin, 0, s, 0, xs);
        @include spacing(padding, 12, 0);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .category-documents-icon {
          path {
            @include var(fill, hint-color);
          }
        }
        .category-documents-icon-collapsed {
          transform: rotate(-90deg);
        }
      }
      .documents-category-list {
        display: none;

        &-open {
          display: initial;
        }
      }
    }
  }

  &-item {
    position: relative;
    display: flex;
    @include var(background-color, agenda-list-item-color);
    &:hover,
    &:has(.dialog-background-layer) {

      @include elevation;
      @include var(background-color, secondary-background-color);


      @include breakpoint(l) {
        .documents-facility-object-document-actions {
          display: flex;
        }
      }
    }

    &:not(:last-child) {
      @include boxShadow(0, 1px, 0, 0);
    }
  }

  &-document {
    flex: 1;
    @include spacing(padding, xxs, xs);
    @include spacing(margin, 0, s, 0, xs);
    @include var(color, secondary-color);
    flex-direction: row;
    display: flex;
    align-items: center;
    text-decoration: none;

    &.unread {
      @include var(background-color, navigation-background-active);
    }

    &-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: flex-start;

      @include breakpoint(s) {
        flex-direction: row;
      }
    }

    .documents-facility-object-document-personal-category-wrapper {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;

      @include breakpoint(s) {
        flex-direction: row;
      }
    }

    &-cat {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;

      @include breakpoint(s) {
        flex-direction: row;
        align-items: center;
        text-align: end;
        justify-content: flex-end;
        flex-basis: 140px;
      }
    }
    cursor: pointer;



    &-actions{

      @include breakpoint(l) {
        @include spacing(right, xxs);
        @include spacing(padding, 2);
        @include var(background-color, secondary-background-color);
        display: none;
        width:40%;
        top: 0;
        bottom: 0;
        position: absolute;
        justify-content: end;
      }
    }

    &-name {
      flex: 1;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
      @include spacing(padding, 0, xs);
    }

    &-info {
      @include spacing(padding-left, xs);
      @include spacing(font-size, 12);
      @include var(color, message-state-closed-color);
      @include breakpoint(s) {
        flex-basis: 100px;
      }
    }

    &-category {
      @include spacing(border-radius, xs);
      @include spacing(padding, 2, xxs);
      @include spacing(border-width, 2);
      @include var("border-color", secondary-background-color);
      border-style: solid;
      white-space: nowrap;
      display: block;
      @include var("color", button-color-lightblue);
      @include spacing(font-size, 12);
      @include var("background-color", button-background-lightblue);
      @include spacing(margin, 0, l, 0, xs);
      @include breakpoint(s) {
        flex-basis: 140px;
      }
    }
    &-personal {
      @include spacing(border-radius, xs);
      @include spacing(padding, 2, xxs);

      @include spacing(border-width, 2);
      @include var("border-color", secondary-background-color);
      border-style: solid;
      white-space: nowrap;
      display: block;
      @include var("color", button-background-red);
      @include spacing(font-size, 12);
      @include var("background-color", personal-background-color);
      @include spacing(margin, 0, xs);
      width: max-content;

      @include breakpoint(s) {
        @include spacing(margin, 0, 2, 0, 0);
      }
    }
  }
}
