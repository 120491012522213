@import "../../../utils";

.invitation-instructions-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;

  button {
    @include spacing(margin-bottom, s);
  }
}
