@import "../../utils";

.dashboard-right-container {
  display: block;

  &-item {
    flex: 1;
    @include elevation;
    @include spacing(margin,0, 4, s);
    @include var(background-color, secondary-background-color);
    @include spacing(border-radius, 4);

    @include breakpoint(l) {
      @include spacing(margin,1, 0, s);
      @include spacing(max-width, 450);
    }

    &:nth-child(2) {
      max-width: calc(100% - 8px);
      @include breakpoint(s) {
        max-width: calc(50% - 8px);
      }
      @include breakpoint(l) {
        max-width: calc(100% - 4px);
      }
    }

    &-title-container{
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      text-decoration: none;
      @include boxShadow(0, 2px, 0, 0);
      @include var(color, primary-color);

      > span {
        @include spacing(margin-right, xs);
      }
    }

    &-title {
      @include spacing(padding, xs, xs, xs, s);
    }

    &-documents {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;
      @include spacing(font-size, 14);
      cursor: pointer;

      @include var(color, secondary-color);
      @include spacing(padding, xxs, xs, 0, 18);

      &:first-child {
        @include spacing(padding-top, xxs);
      }

      &:last-child {
        @include spacing(padding-bottom, xxs);
      }

      &-info {
        display: flex;
        flex-direction: column;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        @include spacing(margin-left, xxs);

        .size {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          @include var(color, hint-color);
          @include spacing(padding-top, 2);

          &-dot {
             @include var(fill, hint-color);;
          }

          span { @include spacing(padding-right, xxs);}
        }

        i {
          border: solid #627177;
          border-width: 0 3px 0  0;
          display: inline-block;
          width: 1px;
          margin-bottom: 4px;
        }
      }

      .icon {
        min-width: 35px;
        min-height: 35px;
      }

    }

    &-dates {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include spacing(font-size, 14);
      cursor: pointer;
      text-decoration: none;
      @include var(color, hint-color);
      @include spacing(padding, xxs, xs, xxs, s);

      .date-container{
        display: flex;
        flex-direction: column;
        @include spacing(margin-right, m);

        &-date {
          font-size: 20px;
          @include var(color, secondary-color);
        }

        &-title {
          @include spacing(margin-bottom, 4);
          @include var(color, secondary-color);
          text-decoration: none;
        }
      }
    }

    &-contact {
      display: flex;
      justify-content: space-between;
      @include spacing(font-size, 14);
      cursor: pointer;
      @include var(color, secondary-color);
      @include spacing(padding, xxs, xs, xxs, s);
      @include boxShadow(0, 2px, 0, 0);

      &-headline-icon-down {
        width: 15px;
        height: 15px;
        @include var(fill, hint-color);
      }

      &-headline-icon-right {
        width: 15px;
        height: 15px;
        transform: rotate(-90deg);
        @include var(fill, hint-color);
      }

      &.open {
        display: flex;
        flex-direction: column;
        min-height: 80px;
        @include boxShadow(0, 2px, 0, 0);

        .title-container {
          display: flex;
          justify-content: space-between;
        }
        .contact-info-card-wrapper {
          display: flex;
          flex-direction: column;
          align-items: start;

          @include breakpoint(s) {
            flex-direction: row;
            align-items: center;
          }

          .contact-info-card {
            @include var(color, hint-color);
            @include spacing(margin-top, xxs);

            &-communication-data {
              @include spacing(margin-top, xxs);

              &-info {
                display: flex;
                justify-content: flex-start;
                @include spacing(margin-top, xxs);

                &-icon {
                  &-path {
                    @include var(fill, primary-color);
                  }
                }

                a,
                div {
                  display: flex;
                  align-items: center;
                  text-decoration: none;
                  @include var(color, primary-color);
                  @include breakpoint(s) {
                    justify-content: end;
                  }
                }
              }
            }
          }
        }

      }

      &:hover {
        @include elevation;
        .dashboard-right-container-item-contact {
          visibility: inherit;
        }
      }
    }
  }

}
