@import "../../utils";

.pagination-container {
  display: flex;
  list-style: none;
  align-items: center;
  @include spacing(margin-top, s);
  @include spacing(padding-left, xxs);

  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    height: 40px;
    border: 1.5px solid ;
    border-radius: 2px;
    background-color: inherit;
    @include var(border-color, border-color);
    @include var(color, list-number-color);
    @include spacing(margin, 0, xxs, 0, xxs);
    @include var(background, secondary-background-color);
    @include var(color,  secondary-color);

    &:last-child {
      @include spacing(margin-right, 0);
    }
   &:disabled{
     cursor: auto;
     opacity: 0.4;
    }
  }
}




