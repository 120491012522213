@import "../../utils";

.goodbye-page-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  @include spacing(margin-top, 104);
  @include spacing(padding, 0, xxs, m);
  @include breakpoint(m) {
    height: 100vh;
    width: 100%;
    @include spacing(padding, m, xl);
    margin-top: 0;
  }
  .goodbye-container {
    @include var(background-color, secondary-background-color);
    @include elevation;
    @include spacing(padding, xs, xs, 20, xs);

    & > * {
      @include spacing(margin, xs, 0);
    }

    a {
      text-decoration: none;
      @include var(color, information-color);
    }
  }

}