@import "../../utils";

.recording-attendance-information-wrapper {

  .recording-attendance-information {
    display: flex;
    align-items: center;
    @include spacing(margin-top, m);

    &-info-text {
      @include spacing(margin-top, s);
    }
  }
}