@import "../../utils";

.message-answer-contacts-create-form {
  @include spacing(padding, xxs);
  @include elevation;

  &-fields {
    display: flex;

    .textarea-field {
      flex: 1;
      @include spacing(padding, 0, xxs);
      textarea {
        @include spacing(padding, xxs);
      }
    }

    .icon-button {
      height: 54px;
    }

  }

  &-preview {
    display: flex;
    overflow: auto;
  }
}