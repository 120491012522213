@import "../../../utils";

.service-provider-list-item {
  width: 100%;
  display: flex;
  @include boxShadow(0, 1px, 0, 0);
  align-items: center;

  .name-wrapper {
    @include spacing(padding, xs);
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    position: relative;

    .provider {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .registered-accounts-count {
      border-radius: 50%;
      background-color: #00e5ff;
      @include var(color, secondary-color);
      @include spacing(width, xs);
      @include spacing(height, xs);
      @include spacing(line-height, xs);
      @include spacing(font-size, 10);
      text-align: center;
      @include spacing(margin-left, s);
    }
  }

  .menu-button {
    border: none;
    box-shadow: none;
    :hover {
      border: none;
      box-shadow: none;
    }
  }

  .icon-button {
    border: none;
    box-shadow: none;
    :hover {
      border: none;
      box-shadow: none;

      circle {
        @include var(fill, primary-color);
      }
    }

  }

  .checkbox-wrapper {

    @include spacing(margin, 0, 0, 0, xs);
  }
}