@import "../../utils";

.central-settings-container {
  flex: 1;
  width: 100vw;
  @include spacing(margin-top, 104);
  @include spacing(padding, 0, xxs, m);

  @include breakpoint(m) {
    flex: 1;
    width: 100%;
    @include spacing(padding, m, xl);
    margin-top: 0;
  }

  &-info-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 0.25rem;
    overflow: auto;
    justify-content: center;
    @include spacing(padding, s, 0, s, 0);
    @include var(background, secondary-background-color);

    @include breakpoint(m) {
      @include spacing(padding, s, xs, s, xs);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .central-settings-container-sections {
      .electronic-communications-container {
        position: relative;
        display: flex;
        align-items: start;
        flex-direction: column;
        @include spacing(padding-left, xs);
        @include spacing(margin-bottom, m);

        @include breakpoint(s) {
          @include spacing(padding-left, xxs);
          flex-direction: inherit;
        }

        .electronic-communications-info {
          max-width: 100%;
          display: flex;
          flex-direction: column;
          @include spacing(margin-right, xs);

          @include breakpoint(s) {
            max-width: 50%;
            min-width: 32%;
          }

          .switch-wrapper {
            @include spacing(margin, m, 0, xxs, 0);
          }

          h3 {
            @include spacing(margin-top, m);
          }

          > span {
            @include spacing(margin-bottom, xxs);
          }

          .electronic-communications-info-save {
            max-width: 100px;
            @include spacing(margin-top, 110);
          }

        }

      }
    }
  }

}