@import "../../utils";

.profile-settings-container {

  &-form {
    display: flex;
    flex-direction: row;
    max-width: 730px;
    margin: auto;
    @include breakpoint(m) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-fields {
      display: flex;
      flex-direction: column;
      @include breakpoint(s) {
        flex-direction: row;
        flex-wrap: wrap;
      }

      .text-input-field,
      .select-field,
      .date-form-container {
        box-sizing: border-box;
        position: relative;
        @include spacing(margin, 0, xs);
        @include breakpoint(s) {
          @include spacing(margin, 0, xxs);
          flex-basis: calc(50% - 16px);
        }
      }

      .date-form-container {
        .date-title {
          display: block;
          @include spacing(padding, xxs, 0);
        }
        input {
          @include var(background-color, input-background-color);
          @include spacing(border-radius, 4);
          @include spacing(padding, xs);
          border: none;
          flex: 1;
          width: 100%;
        }
        input:focus {
          @include var(outline-color, input-focus-color);
          @include var(background-color, secondary-background-color);
        }
      }

      .textarea-field {
        @include spacing(margin, 0, xs);
        @include breakpoint(s) {
          @include spacing(margin, 0, xxs);
          flex-basis: calc(100% - 16px);
        }
      }
      .extra {
        width: 350px;
        border-radius: 0.25rem;

        div {
          border: none;
          background: rgba(#f03e3e, 0.4);
          border-radius: 0.25rem;
          opacity: 0.5;
          height: 43px;
        }
      }

      .calendar {
        @include var(fill, hint-color);
        position: absolute;
        opacity: 0.5;
        @include spacing(right, xxs);
        @include spacing(bottom, 12);
      }

    }

    .actions-buttons-wrapper{
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include spacing(margin, 0, xxs, 0, xxs);
    }
  }

  .info-text {
    @include var(color, icon-grey);
    @include spacing(margin, xs, xs);
    @include breakpoint(s) {
      @include spacing(margin, xs, xxs);
      flex-basis: calc(100% - 16px);
    }
  }

  .error-text {
    @include var(background, form-field-error-background-color);
    @include spacing(padding, xs);
    @include spacing(margin, s);
    border-radius: 5px;

    @include breakpoint(m) {
      @include spacing(padding, xs);
      @include spacing(margin, s, 0, 0, 0);
    }

    span {
      @include var(color, form-field-error-color);
    }
  }
}
