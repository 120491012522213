@import "../../../utils";

.number-input-field {

  &-label {
    display: block;
    @include spacing(padding, xxs, 0);
  }

  &-error {
    @include var(color, form-field-error-color);
  }
  input {
    @include var(background-color, input-background-color);
    @include spacing(border-radius, 4);
    @include spacing(padding, xs);
    border: none;
    flex: 1;
  }
  input:focus {
    @include var(outline-color, input-focus-color);
    @include var(background-color, secondary-background-color);
  }
}