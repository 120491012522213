@import "../../../utils";

.object-component-wrapper {

  .object-component {
    display: flex;
    align-items: start;
    flex-direction: column;
    @include spacing(padding, 0, s);

    @include breakpoint(s) {
      flex-direction: row;
    }

    .object-picture {
        @include spacing(margin-top, xl);
        @include breakpoint(s) {
          flex-basis: 25%;
          @include spacing(margin-left, xl);
          @include spacing(margin-top, 68);
        }
        img {
          width: 100%
        }
    }
    .object-info {
      display: flex;
      width: 100%;
      flex-direction: column;
      @include breakpoint(s) {
        flex-basis: 75%;
        @include spacing(margin-right, xxs);
      }

      h3 {
        @include spacing(margin-bottom, xxs);
      }

      &-divider {
        @include spacing(margin, s, 0);
        @include var(border-top-color, divider-color);
        border-top-width: 1px;
        border-top-style: solid;

      }

      &-inline {
        @include spacing(padding-left, 68);
        @include spacing(margin-top, xs);
      }
      &-list {
        @include spacing(margin-top, m);
        &-item {
          display: flex;
          align-items: center;
          @include spacing(margin-bottom, xxs);

          &-label {
            @include var(color, input-focus-color);
            @include spacing(flex-basis, 160);
            display: flex;
            align-items: center;

            &-icon,
            &-icon-fill {
                @include spacing(margin-right, xxs);
            }
            &-icon-path {
              @include var(stroke, input-focus-color);
            }
            &-icon-fill-path {
              @include var(fill, input-focus-color);
            }
          }

          &-content {
            flex-basis: 50%;
            @include var(color, text-color);
          }
        }
      }
    }
  }
}
