@import "../../utils";

.message-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;

  &-no-news {
    @include spacing(margin, s);
  }
}